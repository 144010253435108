<div class="container mt-4">
  <h1>CONDIZIONI GENERALI</h1>
  <i class="text-muted my-2"
    >Quanto contenuto in questo documento &egrave; da considerarsi opera dell&rsquo;ingegno protetta dal diritto
    d&#39;autore e dalle leggi nazionali, comunitarie e internazionali che sono applicabili. I diritti di utilizzo, i
    diritti di traduzione, di riproduzione e di adattamento totale o parziale e con qualsiasi mezzo, sono riservati per
    tutti i Paesi. Tali condizioni sono implicitamente accettate con la lettura del presente documento e si applicano
    sia alla versione digitale che a qualunque sua riproduzione.</i
  >
  <p class="mt-2">Versione di Giugno 2024</p>
  <p>
    Le presenti Condizioni generali (di seguito definite le &ldquo;Condizioni Generali&rdquo;) disciplinano i termini e
    le condizioni della fornitura alla Societ&agrave; (di seguito anche &ldquo;Destinatario del Servizio&rdquo;)
    registrata al Servizio denominato &ldquo;Spaceship&rdquo; (di seguito anche il &ldquo;Servizio&rdquo;) raggiungibile
    ai seguenti indirizzi: <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a> per il sito
    web e <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a> per
    l&rsquo;applicazione web.
  </p>
  <p>Il Prestatore del Servizio si riserva il diritto di rivedere periodicamente le presenti Condizioni Generali.</p>
  <p>
    Le presenti Condizioni Generali proposte al Destinatario del Servizio sono a sua disposizione in modo da
    consentirgli la memorizzazione e la riproduzione (in accordo a quanto previsto dal decreto di attuazione della
    direttiva europea 2000/31/CE).
  </p>
  <h3 id="h.vvtg40ccvr95">DEFINIZIONI</h3>
  <p>
    Per Fornitore e/o Prestatore di Servizio ( di seguito anche &ldquo;Nativa&rdquo;) si intende &quot;Nativa Srl
    SB&quot; con sede legale al Piazzale Clodio, 22 - 00195 - Roma (P.IVA IT07929980964) indirizzo email
    <a href="mailto:info@nativalab.com">info&#64;nativalab.com</a>.
  </p>
  <p>
    Con il termine &ldquo;Software Spaceship&rdquo; si intende il Software di esclusiva propriet&agrave; del Fornitore e
    di cui detiene tutti i diritti di propriet&agrave; intellettuale, nessuno escluso, utilizzato per la realizzazione
    del Servizio.
  </p>
  <p>
    Con il termine &ldquo;Servizio&rdquo; s&rsquo;intende, il Servizio offerto dal Fornitore alla Societ&agrave;
    registrata al Servizio disponibile all&rsquo;indirizzo web
    <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>, e meglio definito in
    seguito, che consente di avviare il percorso di sostenibilit&agrave;, attraverso alcuni degli strumenti più
    riconosciuti a livello nazionale e internazionale e la conseguente impostazione di un piano di miglioramento del
    proprio profilo di sostenibilità.
  </p>
  <h3 id="h.7b0txyl62qwl">0. PREMESSA</h3>
  <p>
    NATIVA &egrave; la Regenerative Design Company che supporta centinaia di aziende in una radicale evoluzione del
    proprio business, accelerandone la transizione da modelli economici estrattivi a modelli rigenerativi. &Egrave; la
    prima Societ&agrave; Benefit in Europa e B Corp&#8482; in Italia.
  </p>
  <p>La Societ&agrave; che intende fruire del Servizio :</p>
  <ul>
    <li>
      &egrave; consapevole che il contesto attuale impone la necessit&agrave; di dotarsi di solidi strumenti e modelli
      di riferimento per la valutazione delle conseguenze che le scelte dell&rsquo;azienda determinano sulle persone e
      l&rsquo;ambiente;
    </li>
    <li>vuole avviare un percorso di sviluppo sostenibile;</li>
    <li>
      Il percorso &egrave; progettato coerentemente con la Visione, la Missione e i Valori della Societ&agrave; e ha
      l&rsquo;obiettivo di valorizzare e rafforzare il lavoro gi&agrave; svolto negli anni precedenti in ambito
      sostenibilit&agrave; e la creazione di valore per tutti gli stakeholder e rilanciare il lavoro stesso ad un
      livello ancora pi&ugrave; profondo ed evoluto.
    </li>
  </ul>
  <h3 id="h.d8jb5w5w73f9">DOCUMENTI CONTRATTUALI ED EFFICACIA</h3>
  <p>
    La disciplina contrattuale complessiva della fornitura del Servizio concesso alla Societ&agrave; &egrave; contenuta
    nelle presenti Condizioni Generali, nonche&#769; nell&rsquo;Ordine e/o Modulo sottoscritto dalla Societ&agrave;
    on-line, che contiene le condizioni particolari di contratto (di seguito indistintamente &ldquo;Ordine&rdquo; e/o
    &ldquo;Modulo di adesione&rdquo;) quali a titolo esemplificativo e non esaustivo al Piano, al modello del Servizio
    prescelto e al prezzo applicato (Iva esclusa)
  </p>
  <p>Le Condizioni Generali e l&rsquo;Ordine sono di seguito congiuntamente definiti il &ldquo;Contratto&rdquo;.</p>
  <p>
    Il perfezionamento del Contratto avverr&agrave; al momento del corretto e puntuale ricevimento da parte del
    Fornitore del Sevizio dell&rsquo;Ordine, compilato ed accettato dalla Societ&agrave; in ogni sua parte. L&#39;invio
    dell&rsquo;Ordine, comporta l&#39;integrale accettazione da parte della Societ&agrave; di tutti i termini e delle
    condizioni descritti nelle presenti Condizioni Generali, fatte salve le modifiche e/o integrazioni che verranno
    eventualmente apportate dal Prestatore del Servizio e, di tutti gli altri documenti che costituiscono il Contratto.
    A seguito del perfezionamento del pagamento come descritto all&rsquo;art. 6 seguir&agrave; l&#39;invio da parte del
    Prestatore del Servizio all&rsquo;indirizzo email indicato nell&rsquo;Ordine del link attraverso cui la
    Societ&agrave; potr&agrave; creare le proprie credenziali di accesso al Servizio. Resta inteso, in ogni caso, che
    l&rsquo;utilizzo del Servizio da parte del Cliente attesta l&rsquo;accettazione di tutte le condizioni contrattuali.
    La Societ&agrave; inviando l&#39;Ordine prende atto ed accetta che conclude un contratto la cui sola versione valida
    ed efficace &egrave; quella in lingua italiana mentre le altre versioni fornite dal Fornitore in una qualsiasi altra
    lingua straniera sono messe a sua disposizione esclusivamente a titolo di cortesia.
  </p>
  <p>
    Eventuali termini o condizioni diversi o aggiuntivi proposti dalla Societ&agrave; o comunque richiamati da
    quest&rsquo;ultima nei propri documenti, non avranno efficacia alcuna nei confronti del Fornitore, salvo che non
    siano dalla medesima espressamente accettati per iscritto.
  </p>
  <h3 id="h.ug1n9tppdsxg">1. AMBITO DI APPLICAZIONE DELLE CONDIZIONI GENERALI</h3>
  <p>
    1.1. Le Condizioni Generali si applicano alla fornitura del Servizio per la durata, il corrispettivo e le altre
    eventuali condizioni indicate nell&rsquo;Ordine sottoscritto dalla Societ&agrave;.
  </p>
  <h3 id="h.qe85812yxvqj">2. IL SERVIZIO</h3>
  <p>
    2.1. Il Fornitore con il Contratto si impegna a fornire direttamente o tramite terzi alla Societ&agrave;, che
    accetta, a fronte del puntuale pagamento dei Corrispettivi stabiliti nell&rsquo;Ordine, il Servizio indicato
    nell&rsquo;Ordine medesimo.
  </p>
  <p>
    2.2. La Societ&agrave; potr&agrave; fruire del Servizio esclusivamente all&rsquo;indirizzo web
    <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a> attraverso le proprie
    credenziali di accesso (e di cui &egrave; responsabile) cos&igrave; come definite all&rsquo;art. 4, godendo della
    Licenza d&rsquo;uso del Software concessale non in esclusiva e limitata all&rsquo;utilizzo del Software come
    regolato dalle condizioni della Licenza d&rsquo;uso stessa, per tutta la durata del piano prescelto.
  </p>
  <p>
    2.3. Il Fornitore si impegna a fornire il Servizio indicato nell&rsquo;Ordine esclusivamente in favore della
    Societ&agrave; quindi, resta inteso che soggetti terzi, in essi comprese anche societ&agrave; Controllate dello
    stesso gruppo della Societ&agrave;, NON potranno fruire del Servizio specificato nell&rsquo;Ordine.
  </p>
  <p>I servizi oggetto del presente contratto comprendono le seguenti attivit&agrave;:</p>
  <p>
    Il Servizio &egrave; concepito in modalit&agrave; cd. &ldquo;self service integrata&rdquo;, ovvero in autonomia da
    parte della Societ&agrave; cliente, attraverso una piattaforma dedicata che mette a disposizione le
    funzionalit&agrave; adeguate per affrontare il processo di sostenibilit&agrave; e, nel caso di scelta del piano di
    servizio MARS, la possibilità di ottenere assistenza sull'utilizzo del servizio da parte del Team di Supporto
    Spaceship; si precisa che tale supporto dedicato, consiste in assistenza all'uso e/o al funzionamento del
    <i>software</i> e non ha natura di attività di consulenza. Verrà erogato dal Team di Supporto Spaceship a seguito
    della presa in carico della richiesta da parte della società nei giorni feriali, in orario lavorativo 9:00 - 18:00,
    esclusi i giorni festivi e le chiusure aziendali.
  </p>
  <p>Il Servizio permette all&rsquo;azienda di:</p>
  <ul>
    <li>accedere ai Programmi presenti in piattaforma;</li>
    <li>
      accedere ad un archivio documentale online che mette a disposizione uno spazio condiviso per la Societ&agrave; in
      cui salvare tutti i documenti importanti e necessari al completamento del programma;
    </li>
    <li>accedere alle ultime news ed eventi sugli argomenti dei programmi presenti in piattaforma;</li>
    <li>accedere ad una knowledge base sugli argomenti dei programmi presenti in piattaforma;</li>
    <li>
      accedere nel caso dell'adozione del piano MARS al supporto da parte del Team di Supporto Spaceship, come definito
      in precedenza.
    </li>
  </ul>
  <p>
    Il Destinatario del Servizio s&rsquo;impegna a riconoscere espressamente che la limitazione di responsabilit&agrave;
    di cui all&rsquo;art. 9, in particolare in ordine al Servizio, &egrave; pattuita come condizione essenziale per le
    prestazioni assunte dal Fornitore in base ai costi indicati in questo contratto e pertanto al di fuori di quanto
    espressamente pattuito, rinuncer&agrave; dunque, senza riserva ad azioni riparatorie.
  </p>
  <p>
    2.4. NON sono oggetto del Contratto tutte le attivit&agrave; che non sono espressamente oggetto del Contratto
    stesso, a titolo esemplificativo e non esaustivo:
  </p>
  <p>
    2.4.1. tutte le attivit&agrave; di certificazione, infatti Spaceship non &egrave; un servizio di certificazione;
  </p>
  <p>
    2.4.2. tutte le attivit&agrave; strategiche prodromiche e conseguenti alla formulazione del piano di azioni di
    miglioramento suggerite;
  </p>
  <p>
    2.4.3. tutte le attivit&agrave; di Terzi conseguenti e/o correlate al percorso di sostenibilit&agrave; della
    Societ&agrave;, quali ad esempio le attivit&agrave; offerte da terzi attraverso il proprio sito web raggiungibile
    all&rsquo;indirizzo e al quale il Software reindirizza, quali ad esempio la misurazione della performance economica,
    sociale e ambientale della Societ&agrave; e in caso di scelta da parte della Societ&agrave; di attuazione di alcune
    delle azioni di compensazione suggerite nel piano di miglioramento.
  </p>
  <p>
    2.4.4. tutte le attivit&agrave; di backup specifico dei dati e/o dei contenuti (in senso generale) immessi e/o
    trattati dalla Societ&agrave;. Pertanto la medesima Societ&agrave; prende atto ed accetta che costituisce suo
    esclusivo onere procurarsi e mantenere una copia dei dati e/o dei contenuti (in senso generale) immessi e/o trattati
    mediante il Servizio, restando inteso che una volta terminato il Contratto e/o in caso di disdetta e/o cessazione
    anche di un solo piano e/o in generale del Contratto tali dati e/o tali contenuti (in senso generale) potranno
    essere non pi&ugrave; recuperabili. In ogni caso la Societ&agrave; solleva il Fornitore del Servizio da ogni e
    qualsiasi responsabilit&agrave; per l&#39;eventuale perdita o il danneggiamento totale o parziale di dati e/o
    contenuti (in senso generale) immessi e/o trattati dalla Societ&agrave; stesso mediante il Servizio. Resta ad
    esclusivo carico della Societ&agrave; l&#39;eventuale ripristino dei dati e/o dei contenuti (in senso generale)
    dalla medesima immessi e/o trattati, previa riattivazione del Servizio di cui si tratta, se necessario concludendo
    un nuovo Contratto.
  </p>
  <p>
    2.4.5. Tutte le attività di consulenza e le attività di supporto che NON siano relative e/o collegate al
    funzionamento e all'uso del <i>software</i>
  </p>
  <p>
    2.5. L&rsquo;obbligazione del Fornitore di erogare il Servizio si basa sulle informazioni ricevute dalla
    Societ&agrave; e di tali informazioni la Societ&agrave; si assume piena ed esclusiva responsabilit&agrave;. La
    Societ&agrave; prende atto che non fornendo le informazioni richieste non potr&agrave; usufruire del Servizio.
  </p>
  <p>
    2.6. La Societ&agrave; prende altres&igrave; atto e accetta che il Fornitore al fine di migliorare il Servizio e
    offrire un Servizio in costante miglioramento ha il diritto di utilizzare in forma anonimizzata e/o aggregata i dati
    immessi dalla Societ&agrave; per pianificare attivit&agrave; strategiche e gestionali, eseguire ricerche di mercato
    e analisi di business intelligence e analisi statistica. Si precisa che per &quot;forma anonimizzata&quot; si
    intende un dato gestito in modo da non essere riconducibile alla Societ&agrave; che lo ha immesso e che per
    &ldquo;forma aggregata&quot; si intende un dato elaborato unitamente ad altri in modo da rendere estremamente
    difficile ottenere l&#39;originario dato inserito dalla Societ&agrave;.
  </p>
  <p>
    2.7 La Societ&agrave; prende altres&igrave; atto che il Fornitore potr&agrave; effettuare con i dati forniti
    attivit&agrave; di marketing generico ovvero: attivit&agrave; promozionali e commerciali relative a servizi/prodotti
    analoghi a Spaceship, a titolo esemplificativo e non esaustivo la proposta di rinnovo del Servizio Spaceship o di
    webinar o di whitepaper e attivit&agrave; di marketing diretto ovvero: attivit&agrave; promozionali e commerciali
    relative alle iniziative commerciali, culturali, formative e di organizzazione di eventi del Fornitore.
  </p>
  <p>
    2.8. Le Parti s&rsquo;impegnano a prestare la massima collaborazione per consentire alla Societ&agrave; stessa di
    usufruire al meglio del Servizio.
  </p>
  <p>2.9. Sulla base di principi e valori condivisi le Parti si impegnano a:</p>
  <p>
    &#9679; condividere il processo decisionale interno per identificare insieme tempistiche di riferimento, milestones
    e step decisionali per evitare eventuali punti di blocco sul dialogo che potrebbero eventualmente nascere &ndash;
    Trasparenza
  </p>
  <p>
    &#9679; dare feedback in maniera tempestiva agli input forniti dall&rsquo;altra parte in modo da agevolare
    vicendevolmente lo svolgimento delle attivit&agrave; e consentire il raggiungimento degli obiettivi nei tempi
    concordati &ndash; Comunicazione
  </p>
  <p>
    &#9679; rispettare i ruoli reciproci affinch&eacute; le attivit&agrave; descritte vengano svolte ricercando sempre
    il beneficio di tutti gli attori coinvolti, sia nel breve che nel medio e lungo termine - Commitment
  </p>
  <h3 id="h.u5vhi7n0i8v0">3. OBBLIGHI DEL CLIENTE</h3>
  <p>3.1. La Societ&agrave;, con il Contratto, si impegna a:</p>
  <p>(a) corrispondere i Corrispettivi dovuti e previsti nell&rsquo;Ordine;</p>
  <p>
    (b) fornire tutte le Informazioni e a prestare tutte le garanzie necessarie all&rsquo;esecuzione da parte del
    Fornitore delle obbligazioni derivanti dal Contratto; di tali informazioni e garanzie la Societ&agrave; si assume
    piena ed esclusiva responsabilit&agrave;;
  </p>
  <p>
    (c) dotarsi autonomamente di risorse, hardware e software, nonch&eacute; di una Connettivit&agrave; adeguate al fine
    di poter fruire del Servizio;
  </p>
  <p>
    (d) usare il Software e il Servizio in maniera conforme al Contratto ed esclusivamente per le finalit&agrave; cui
    esso &egrave; destinato.
  </p>
  <h3>4. CREDENZIALI DI ACCESSO</h3>
  <p>
    4.1. Il Destinatario del Servizio potr&agrave; fruire del Servizio autenticandosi all&rsquo;indirizzo web:
    <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>
    attraverso le proprie credenziali definite attraverso il link inviato all&rsquo;indirizzo email indicato
    nell&rsquo;Ordine; utilizzando questo link l&#39;utente pu&ograve; impostare in autonomia una password che viene
    salvata nel database crittografata.
  </p>
  <p>
    Il Destinatario del Servizio prende atto che le credenziali da lui gestite dovranno ottemperare agli standard di
    riferimento per la gestione delle credenziali.
  </p>
  <p>
    4.2 Il Fornitore non disporr&agrave; quindi delle Credenziali di Accesso e non potr&agrave; utilizzare il Servizio
    direttamente in luogo della Societ&agrave;.
  </p>
  <p>
    4.3 La Societ&agrave; &egrave; consapevole del fatto e accetta che il Fornitore (anche tramite Terzi) pur non
    potendo accedere direttamente al profilo dedicato alla Societ&agrave; (cd. account) e utilizzare il Servizio,
    avr&agrave; il diritto di utilizzare in forma anonimizzata e/o aggregata i dati immessi dalla Societ&agrave; per
    pianificare attivit&agrave; strategiche e gestionali, eseguire ricerche di mercato e analisi di business
    intelligence e analisi statistica come meglio specificato all&rsquo;art. 2.
  </p>
  <p>
    4.4. La Societ&agrave; &egrave; consapevole del fatto che la conoscenza delle credenziali di accesso da parte di
    soggetti terzi consentirebbe a questi ultimi la fruizione non autorizzata del Servizio e l&rsquo;accesso agli
    eventuali dati inseriti. La Societ&agrave; sar&agrave; in ogni caso ritenuta esclusiva responsabile per ogni
    utilizzo, autorizzato o meno, del Servizio mediante le Credenziali di Accesso.
  </p>
  <p>
    4.6. la Societ&agrave; &egrave; tenuta a custodire e a far s&igrave; che ciascun suo Utente custodisca, gestisca e
    aggiorni periodicamente le Credenziali di Accesso con la massima riservatezza e con la massima diligenza,
    obbligandosi a non cederle n&eacute; a consentirne l&rsquo;uso a terzi non espressamente autorizzati.
  </p>
  <p>
    4.7. Il Fornitore e/o gli eventuali suoi Partner non potranno in alcun caso essere ritenuti responsabili di
    qualsiasi danno, diretto e/o indiretto, che dovesse derivare alla Societ&agrave;, a ciascun Utente e/o a terzi in
    conseguenza della mancata osservanza delle previsioni di cui al presente articolo.
  </p>
  <p>5. EVOLUZIONE DEL SERVIZIO</p>
  <p>
    5.1. La Societ&agrave; prende atto e accetta che, laddove ritenuto opportuno a insindacabile giudizio del Fornitore,
    l&#39;evoluzione del servizio potr&agrave; determinare la modifica o l&rsquo;eliminazione di alcune
    funzionalit&agrave; del Servizio.
  </p>
  <p>
    5.2. La Societ&agrave; esonera il Fornitore da qualsivoglia responsabilit&agrave; connessa ad eventuali danni
    derivanti da potenziali evoluzioni del Servizio, salvo tali danni derivino da dolo o colpa grave del Fornitore.
  </p>
  <h3 id="h.yeektofd1svj">6. CORRISPETTIVO, MODALIT&Agrave; E TERMINI DI PAGAMENTO, RIVENDITORE DEL SERVIZIO</h3>
  <p>
    6.1. La Societ&agrave; si impegna a corrispondere per la fornitura del Servizio il Corrispettivo indicato
    nell&rsquo;Ordine, cui si viene indirizzati dalla pagina web del Servizio
    <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a> secondo le modalit&agrave; e le
    tempistiche ivi previste a seconda del piano prescelto, contenente uno o pi&ugrave; programmi e anche a seconda del
    piano del Servizio prescelto: piano denominato &ldquo;Moon&rdquo; e piano denominato &ldquo;Mars&rdquo;.
  </p>
  <p>Nello specifico:</p>
  <ul>
    <li>l&rsquo;importo di attivazione &ldquo;una tantum&rdquo; del Servizio e,</li>
    <li>l&rsquo;importo di abbonamento annuale al Servizio relativo a ciascuna annualit&agrave;.</li>
  </ul>
  <p>
    La Societ&agrave; prende atto ed accetta che costituisce suo espresso ed esclusivo onere provvedere al pagamento del
    corrispettivo per l&rsquo;attivazione del Servizio e il rinnovo automatico del Servizio medesimo in tempo utile al
    fine di poterne garantire la continuit&agrave; e, comunque, prima che il medesimo sia disattivato per la scadenza
    del Contratto, tenendo a tal fine in considerazione anche i tempi di lavorazione dei pagamenti.
  </p>
  <p>
    Alla data di efficacia delle presenti Condizioni Generali il processo di pagamento del Corrispettivo viene gestito
    attraverso il Servizio di pagamento offerto da Paddle.com Market Ltd ( in seguito per brevit&agrave; anche
    &ldquo;Paddle&rdquo;) in accordo ai propri Termini e condizioni indicati per i clienti aziendali al seguente
    indirizzo web:
    <a href="https://www.paddle.com/legal/invoiced-business-terms"
      >https://www.paddle.com/legal/invoiced-business-terms</a
    >
    e che si invita la Societ&agrave; a leggere attentamente.
  </p>
  <p>
    Salvo specifico, separato e diverso accordo tra le Parti, il pagamento del corrispettivo dovuto per la
    sottoscrizione del Servizio dovr&agrave; essere effettuato dalla Societ&agrave; contestualmente all&rsquo;invio
    dell&rsquo;Ordine e comunque anticipatamente rispetto all&#39;attivazione dello stesso Servizio, secondo le
    modalit&agrave; prescelte dalla Societ&agrave; nel corso della procedura telematica di acquisto, modalit&agrave; che
    sono specificate in modo prominente nella pagina di riepilogo dell&rsquo;Ordine, gestita da Paddle, cui la
    Societ&agrave; viene reindirizzata in seguito alla compilazione del form presente nella pagina di acquisto del
    Servizio.
  </p>
  <p>
    6.2. Tutti i Corrispettivi devono intendersi al netto di I.V.A. e degli eventuali altri oneri di legge. Per ciascun
    pagamento effettuato dalla Societ&agrave; Paddle emetter&agrave; la relativa fattura. A tutti gli importi fatturati
    sar&agrave; applicata l&rsquo;Iva dovuta e eventuali altri oneri fiscali derivanti dall&rsquo;esecuzione del
    Contratto, che saranno a carico della Societ&agrave;. In ogni caso, la Societ&agrave; solleva il Prestatore del
    Servizio e Paddle in qualit&agrave; di rivenditore da ogni e qualsiasi responsabilit&agrave; derivante da
    transazioni o pagamenti effettuati.
  </p>
  <p>
    6.3. La Societ&agrave; prende atto e accetta espressamente che i Corrispettivi sono soggetti ad aggiornamento
    annuale nella misura del 100% della variazione in aumento dell&rsquo;indice ISTAT dei prezzi della produzione dei
    servizi, calcolato come media degli ultimi dodici mesi.
  </p>
  <p>
    6.4. La Cliente prende atto che il Servizio &egrave; soggetto, per sua stessa natura, ad una costante evoluzione
    tecnologica e normativa che richiede continue e onerose attivit&agrave; di aggiornamento, sviluppo e, in alcuni
    casi, di sostituzione, necessarie al fine di garantire la sua funzionalit&agrave;. In ragione di quanto precede, il
    Fornitore avr&agrave; il diritto di modificare i Corrispettivi anche in misura superiore all&rsquo;indice ISTAT.
  </p>
  <p>
    6.5. Fermo restando quanto previsto al paragrafo che precede, qualora, durante l&rsquo;esecuzione del Contratto,
    dovessero verificarsi circostanze dipendenti dai Cloud provider su cui &egrave; eventualmente basata
    l&rsquo;infrastruttura o comunque da circostanze imprevedibili tali da rendere maggiormente onerosa
    l&rsquo;erogazione del Servizio da parte del Fornitore, quest&rsquo;ultimo avr&agrave; diritto di percepire un equo
    compenso una tantum ovvero di modificare unilateralmente il Corrispettivo.
  </p>
  <p>
    6.6. Resta inteso che in caso di mancato o ritardato pagamento di una qualsiasi somma dovuta ai sensi del Contratto
    e altres&igrave; in tutti casi ragionevolmente ritenuti tali da parte di Paddle, in qualit&agrave; di rivenditore
    del Servizio, il Fornitore avr&agrave; il diritto di sospendere e/o interrompere la fruizione del Servizio e
    sospendere e/o interrompere la prestazione di qualsivoglia servizio ad esso relativo, fermo quanto previsto di
    seguito in ordine all&rsquo;esercizio della clausola risolutiva espressa. Paddle in qualit&agrave; di rivenditore
    del Servizio potr&agrave;, secondo i propri termini e le proprie condizioni di Servizio indicati all&rsquo;indirizzo
    <a href="https://www.paddle.com/legal/invoiced-business-terms"
      >https://www.paddle.com/legal/invoiced-business-terms</a
    >. a titolo esemplificativo e non esaustivo: (i) risolvere il Contratto restando a carico della Parte inadempiente
    il pagamento dell&rsquo;importo dovuto previsto nell&rsquo;Ordine; e/o (ii) precludere l&rsquo;accesso al Servizio.
    Durante la sospensione e/o interruzione del Servizio il Cliente non potr&agrave; avere accesso a dati e/o
    informazioni e/o contenuti dal medesimo immessi e/o trattati nel Software per l&rsquo;erogazione del Servizio.
  </p>
  <p>
    Se entro un congruo termine dalla sospensione e/o interruzione del Servizio la Societ&agrave; non avr&agrave;
    provveduto ad effettuare il pagamento, tale Servizio verr&agrave; disattivato.
  </p>
  <p>
    6.7. La Societ&agrave; rinuncia a proporre eccezioni senza avere preventivamente adempiuto alle proprie obbligazioni
    di pagamento ai sensi del presente articolo.
  </p>
  <h3 id="h.jia3mznyyjuv">7. PARTNER</h3>
  <p>
    Il Fornitore, nell&rsquo;adempiere alle proprie obbligazioni di cui al Contratto, potr&agrave; avvalersi, a propria
    insindacabile discrezione, della cooperazione tecnica, organizzativa e commerciale di propri Partner e/o consulenti,
    ai quali potr&agrave; affidare la prestazione di alcune o tutte le attivit&agrave; elencate nel Contratto.
  </p>
  <h3 id="h.4j4pvjafez9z">8. PROPRIET&Agrave; INTELLETTUALE</h3>
  <p>
    8.1. Tutti i diritti di Propriet&agrave; Intellettuale, ivi inclusi i relativi diritti di sfruttamento economico, ad
    eccezione di quelli di terze parti, sul Software, sull&rsquo;omonimo Servizio, sulla documentazione, sui video,
    sulle interfacce grafiche, sui contenuti in generale del sito, sulle evoluzioni, sui lavori preparatori, sui lavori
    derivati sui marchi e loghi che sono contenuti o correlati, al Software e al Servizio, sono e rimangono, in tutto e
    in parte e ovunque nel mondo, di esclusiva titolarit&agrave; del Fornitore.
  </p>
  <p>
    8.2. In seguito al perfezionamento del Contratto e limitatamente alla sua durata, il Fornitore concede alla
    Societ&agrave; una Licenza d&rsquo;uso del Software non esclusiva e non cedibile esclusivamente per la fruizione del
    Servizio, in accordo ai termini e alle condizioni del Contratto.
  </p>
  <p>
    8.3. La Societ&agrave; si impegna, anche espressamente per ciascun proprio Utente, ad utilizzare il Software e il
    Servizio medesimo, entro i limiti della Licenza concessa di cui all&rsquo;art. 8.2 e nel rispetto dei diritti di
    Propriet&agrave; Intellettuale del Fornitore. La Societ&agrave; fatti in ogni caso salvi gli inderogabili limiti di
    Legge, non potr&agrave; a titolo esemplificativo e non esaustivo: (a) aggirare le limitazioni tecniche e le misure
    tecnologiche di protezione presenti nel Software, ivi incluso il sistema di autenticazione; (b) commercializzare a
    qualsivoglia titolo il Software e il Servizio e qualsiasi segno distintivo ad essi associato.
  </p>
  <p>
    8.4. Restano altres&igrave; in capo al Fornitore e/o, se del caso, ai terzi proprietari, tutti i diritti sui marchi,
    loghi, nomi, nomi a dominio e altri segni distintivi comunque associati al Software e al Servizio medesimo, con la
    conseguenza che la Societ&agrave; non potr&agrave; in alcun modo utilizzarli senza la preventiva autorizzazione del
    Fornitore (e/o del terzo titolare).
  </p>
  <p>
    8.5. In particolare tutti i diritti sui marchi, loghi, nomi, nomi a dominio, altri segni distintivi e contenuti in
    generale comunque associati a B Lab&#8482; costituiscono espressamente proprietà intellettuale di B Lab&#8482;;
    inoltre la proprietà intellettuale di B Lab&#8482;, compresi i nomi “Certified B Corporation&#8482;”, “B
    Corporation&#8482;”, “B Corp&#8482;”, e il logo “Certified B Corporation&#8482;”, sono marchi registrati. Ciascun
    eventuale riferimento diretto e/o indiretto del Software e/o del Servizio ai marchi, loghi, nomi, nomi a dominio,
    altri segni distintivi e contenuti in generale oggetto di proprietà intellettuale di B lab&#8482; è eseguita
    esclusivamente a fini divulgativi ed educativi.
  </p>
  <h3 id="h.ec6gqsp3amc0">9. RESPONSABILIT&Agrave; E DICHIARAZIONI DEL CLIENTE</h3>
  <p>
    9.1. Con l&rsquo;accettazione delle Condizioni Generali, la Societ&agrave; dichiara di (i) avere tutti i diritti e
    poteri necessari per concludere e dare esecuzione piena ed efficace al Contratto e di (ii) voler utilizzare il
    Software e il Servizio nell&rsquo;ambito della propria attivit&agrave; imprenditoriale, artigianale, commerciale o
    professionale e che, pertanto, non si applicano nei suoi confronti le disposizioni del D.Lgs. 206/2005 e ss. mm. a
    protezione dei consumatori.
  </p>
  <p>
    9.2. La Societ&agrave; si impegna a far s&igrave; che le disposizioni del Contratto siano rispettate da ciascun
    proprio Utente, ivi inclusi i rispettivi dipendenti e/o collaboratori. Anche ai sensi dell&rsquo;art. 1381 c.c., la
    Societ&agrave; &egrave; considerata esclusivo responsabile dell&rsquo;operato di tali soggetti e garantisce
    altres&igrave; il rispetto di tutte le normative applicabili, ivi incluse quelle in materia fiscale e civile.
  </p>
  <p>
    9.3. In generale il Prestatore del Servizio non &egrave; responsabile delle informazioni memorizzate dal
    Destinatario del Servizio. Nello specifico &egrave; fatto divieto di utilizzare il Servizio in modo improprio, al
    fine di depositare, conservare, inviare, pubblicare, trasmettere e/o condividere dati, applicazioni o documenti
    informatici che a titolo esemplificativo e non esaustivo: (a) siano in contrasto o violino i diritti di
    Propriet&agrave; Intellettuale di titolarit&agrave; del Fornitore e/o di terzi; (b) abbiano contenuti
    discriminatori, diffamatori, calunniosi o minacciosi; (c) contengano materiali pornografico, pedopornografico,
    osceno o comunque contrario alla pubblica morale; (d) contengano virus, o, comunque, altri elementi informatici di
    contaminazione o distruzione; (e) costituiscano attivit&agrave; di spamming, phishing e/o simili; (f) siano in ogni
    caso in contrasto con le disposizioni normative e/o regolamentari applicabili.
  </p>
  <p>
    9.4. Il Fornitore si riserva il diritto di sospendere la fornitura del Servizio, ovvero di impedire l&rsquo;accesso
    al Servizio, qualora venga a conoscenza di una violazione di quanto previsto nel presente articolo e/o venga
    avanzata espressa richiesta in tal senso da un organo giurisdizionale o amministrativo in base alle norme vigenti.
    In tal caso, il Fornitore comunicher&agrave; alla Societ&agrave; le motivazioni della sospensione, salva la
    facolt&agrave; di risolvere il Contratto ai sensi dell&#39;articolo art. 17.
  </p>
  <p>
    9.5. La Societ&agrave; prende atto che il Servizio pu&ograve; far uso di software cosiddetto Open Source e si
    impegna, anche per ciascun proprio Utente, ad osservare i termini e le condizioni ad essi specificamente
    applicabili.
  </p>
  <h3 id="h.xvgvdsm43ya8">10. RITIRO DAL MERCATO E SOSTITUZIONE</h3>
  <p>
    La Societ&agrave; prende atto che il Servizio e gli ambienti nei quali esso opera sono soggetti, per loro natura, ad
    una costante evoluzione che pu&ograve; determinare la loro obsolescenza e, in alcuni casi,
    l&rsquo;opportunit&agrave; del ritiro dal mercato e/o, eventualmente, di sostituzione con nuove soluzioni
    tecnologiche. Pertanto, il Fornitore e/o gli eventuali Terzi cloud provider su cui &egrave; basata
    l&rsquo;infrastruttura cloud del Servizio, hanno la facolt&agrave;, a loro insindacabile giudizio, anche nel corso
    della durata del presente Contratto, di ritirare dal mercato il Servizio e/o i relativi componenti cloud
    (eventualmente sostituendoli con nuove soluzioni tecnologiche). In tal caso: (a) il Fornitore comunicher&agrave; al
    Cliente anche a mezzo della pubblicazione sul sito, con un congruo preavviso, che intende ritirare dal mercato il
    Servizio (il &ldquo;Servizio Obsoleto&rdquo;); (b) la comunicazione che precede (&ldquo;Comunicazione di
    Ritiro&rdquo;) includer&agrave; una descrizione dell&rsquo;eventuale nuovo Servizio (il &ldquo;Nuovo
    Servizio&rdquo;) che sostituir&agrave; il Servizio Obsoleto, restando inteso che il Nuovo Servizio potr&agrave;
    basarsi su tecnologie diverse; (c) in caso di sostituzione con un Nuovo Servizio, il Cliente avr&agrave; il diritto,
    esercitabile entro 15 giorni dalla data della Comunicazione di Ritiro, di recedere dal Contratto; in caso contrario,
    il Contratto continuer&agrave; ad esplicare i propri effetti con riferimento al Nuovo Servizio e ogni riferimento al
    Servizio Obsoleto dovr&agrave; intendersi riferito al Nuovo Servizio.
  </p>
  <h3 id="h.2wdy0x1rxghq">11. MANLEVA</h3>
  <p>
    La Societ&agrave; si impegna a manlevare e tenere indenne il Fornitore da qualsiasi danno, pretesa,
    responsabilit&agrave; e/o onere, diretti o indiretti e comprese le ragionevoli spese legali, che dovesse subire o
    sopportare in conseguenza dell&rsquo;inadempimento da parte della Societ&agrave; e/o di ciascun Utente di ciascuno
    degli obblighi previsti dal Contratto e, in particolare, di quanto previsto dagli articoli 3. (Obblighi del
    Cliente), 4. (Credenziali di Accesso), 6. (Corrispettivo, Modalit&agrave; e Termini di Pagamento, Rivenditore Del
    Servizio), 8. (Propriet&agrave; Intellettuale), 9. (Responsabilit&agrave; e dichiarazioni del Cliente), 10. (Ritiro
    dal mercato e sostituzione dei prodotti), 15. (Durata, Disdetta del Servizio E Policy Di Rimborso (Cd. refund
    Policy)), e 21. (Cessione del contratto).
  </p>
  <h3 id="h.k5lzak1vemyu">12. RESPONSABILIT&Agrave; DEL FORNITORE</h3>
  <p>
    12.1. Il Fornitore assume esclusivamente le obbligazioni oggetto del Contratto garantendo che il Servizio
    sar&agrave; erogato a regola d&rsquo;arte secondo l&rsquo;ordinaria diligenza del settore di riferimento.
  </p>
  <p>
    12.2. Il Fornitore non sar&agrave; tenuto ad intervenire in caso di malfunzionamenti dovuti al mancato rispetto da
    parte della Societ&agrave; delle norme operative, all&#39;errata utilizzazione e/o all&#39;impiego atipico del
    Servizio.
  </p>
  <p>
    12.3. La presente garanzia &egrave; subordinata al corretto funzionamento ed al corretto uso
    dell&rsquo;infrastruttura hardware, del software di sistema e delle risorse di rete della Societ&agrave; anche di
    propriet&agrave; di terzi, e del corretto uso del Servizio.
  </p>
  <p>
    12.4. La Societ&agrave; accetta e d&agrave; atto del fatto che nessun prodotto software &egrave; esente da errori e
    riconosce di essere stato specificamente avvisato della necessit&agrave; di effettuare una copia di sicurezza dei
    dati.
  </p>
  <p>
    12.5. Il Fornitore non potr&agrave; essere ritenuto responsabile per danni, diretti o indiretti, di qualsiasi natura
    ed entit&agrave;, che dovessero derivare alla Societ&agrave; e/o a ciascun Utente e/o a terzi in conseguenza
    dell&rsquo;uso del Servizio in maniera non conforme a quanto previsto dal Contratto e/o dalle leggi vigenti.
  </p>
  <p>
    12.6. Il Fornitore non sar&agrave; in alcun modo responsabile di eventuali malfunzionamenti e/o mancata fruizione
    del Servizio che derivino da una Connettivit&agrave; inadeguata.
  </p>
  <p>
    12.7. In nessun caso il Fornitore potr&agrave; essere ritenuto responsabile per eventuali danni o perdite, di
    qualunque natura o entit&agrave;, derivanti dalle elaborazioni effettuate mediante il Servizio essendo in ogni caso
    esclusive prerogative della Societ&agrave; tutte le attivit&agrave; prodromiche (quali ad es. la corretta
    individuazione dei dati da inserire) e conseguenti e comunque correlate all&rsquo;ottenimento della certificazione B
    Corp&#8482; e dell&rsquo;adozione dello status di Societ&agrave; Benefit e al piano delle azioni di miglioramento.
  </p>
  <p>
    12.8. Salvo che ci&ograve; sia necessario ad adempiere a disposizioni di legge e/o a richieste
    dell&rsquo;autorit&agrave; giudiziaria, il Fornitore, non &egrave; tenuto in alcun modo alla verifica dei dati e dei
    contenuti immessi dal Cliente e/o da ciascun Utente attraverso il Servizio e, pertanto, non potr&agrave; in alcun
    modo essere ritenuto responsabile per danni e/o perdite, diretti o indiretti e di qualsiasi natura, derivanti da
    errori e/o omissioni di tali dati o connessi alla loro natura e/o caratteristiche.
  </p>
  <p>
    12.9. Ferma la responsabilit&agrave; del Fornitore per la conformit&agrave; del Servizio alle caratteristiche
    descritte per gli usi e gli scopi consentiti per i quali &egrave; stato realizzato, Il Fornitore, fatti salvi gli
    inderogabili limiti di legge, non potr&agrave; in nessun caso essere ritenuto responsabile per qualsiasi danno
    (diretto o indiretto), costo, perdita e/o spesa che la Societ&agrave; e/o terzi dovessero subire in conseguenza di
    attacchi informatici, attivit&agrave; di hacking e, in generale, accessi abusivi e non autorizzati da parte di terzi
    al Servizio, al Software e, in generale, ai sistemi informatici della Societ&agrave; e/o del Fornitore, dai quali
    possano derivare, a titolo esemplificativo e non esaustivo, le seguenti conseguenze: (i) mancata fruizione del
    Servizio; (ii) perdite e/o furto di dati di titolarit&agrave; o comunque nella disponibilit&agrave; della
    Societ&agrave;; e (iii) danneggiamento dei sistemi hardware e/o software e/o alla Connettivit&agrave; del Cliente.
  </p>
  <p>
    12.10. Salvo il caso di dolo o colpa grave, la responsabilit&agrave; del Fornitore non potr&agrave; mai eccedere
    l&rsquo;ammontare dei Corrispettivi pagati dalla Societ&agrave; ai sensi del presente Contratto nell&rsquo;anno di
    riferimento alla data in cui si &egrave; verificato l&rsquo;evento dal quale discende la responsabilit&agrave; del
    Fornitore. Il Fornitore non potr&agrave; essere ritenuto responsabile per eventuali danni da lucro cessante, mancato
    guadagno o danni indiretti, perdita o danneggiamento di dati, fermo attivit&agrave;, perdita di opportunit&agrave;
    commerciali o di benefici di altro genere, pagamento di penali, ritardi o altre responsabilit&agrave; della
    Societ&agrave; e/o delle Controllate verso terzi.
  </p>
  <p>
    12.11. Il Fornitore non assume o fornisce alcuna garanzia sulla qualit&agrave;, sulle capacit&agrave;, sulle
    operazioni, sul rendimento ed idoneit&agrave; dei software di terzi e dei macchinari di terzi con cui il Software e
    conseguentemente il Servizio opera. Tutte le questioni relative alla qualit&agrave;, capacit&agrave;, operazioni,
    rendimento, idoneit&agrave; e funzionamento in generale dei software di terzi, dei servizi e dei macchinari di terzi
    sono di esclusiva competenza e responsabilit&agrave; del titolare dei diritti di sfruttamento economico e/o del
    Licenziatario, del rivenditore o fornitore di tali software, servizi o macchinari.
  </p>
  <h3 id="h.504ydyyjo7y7">13. MODIFICHE UNILATERALI</h3>
  <p>
    13.1. La Societ&agrave; prende atto e accetta espressamente la eventuale esigenza da parte del Fornitore di
    adeguamento e/o di modifica del Servizio offerto e/o delle evoluzioni del Servizio necessari alla loro fruizione,
    date le caratteristiche intrinseche della prestazione del Servizio, con riferimento specifico alle verosimili future
    evoluzioni tecnologiche e normative del settore e alle esigenze del mercato stesso. Il Fornitore pertanto accetta
    che il Servizio potr&agrave; essere modificato dal Fornitore in qualsiasi momento, dandone semplice comunicazione
    alla Societ&agrave;.
  </p>
  <p>
    13.2. In tal caso, la Societ&agrave; avr&agrave; la facolt&agrave; di recedere dal Contratto con comunicazione
    scritta inviata al Fornitore a mezzo raccomandata con ricevuta di ritorno o a mezzo posta certificata nel termine di
    15 giorni dal ricevimento della comunicazione, anche a mezzo della pubblicazione sulla pagina relativa alle
    Condizioni Generali di Contratto da parte del Fornitore di cui al paragrafo che precede.
  </p>
  <p>
    13.3. In mancanza di esercizio della facolt&agrave; di recesso da parte del Cliente, nei termini e nei modi sopra
    indicati, le modifiche al Contratto si intenderanno da quest&rsquo;ultimo definitivamente conosciute ed accettate e
    diverranno definitivamente efficaci e vincolanti.
  </p>
  <h3 id="h.dhyrsvtyjmpy">14. SOSPENSIONE E INTERRUZIONE</h3>
  <p>
    14.1. Il Fornitore compir&agrave; ogni ragionevole azione per garantire la massima disponibilit&agrave; del Servizio
    nei limiti delle obbligazioni assunte con il Contratto ovvero fatta eccezione per quanto direttamente e/o
    indirettamente riconducibile ai cloud provider ove &egrave; basata l&#39;infrastruttura cloud della Societ&agrave;.
    La Societ&agrave;, tuttavia, prende atto ed accetta che il Fornitore potr&agrave; sospendere e/o interrompere la
    fornitura del Servizio, previa sua comunicazione, qualora si dovessero rendere necessari interventi di manutenzione
    ordinaria o straordinaria all&rsquo;Infrastruttura Cloud e/o al Software. In tali casi, il Fornitore si impegna a
    ripristinare la disponibilit&agrave; del Servizio nel minor tempo possibile.
  </p>
  <p>
    14.2. Fatto salvo quanto previsto agli artt. 6.6, 9 e 17.2, il Fornitore, ferme le facolt&agrave; in tal senso in
    capo al cloud provider di riferimento, si riserva altres&igrave; la facolt&agrave; di sospendere o interrompere la
    fornitura del Servizio: (a) in caso di mancato o ritardato pagamento, totale o parziale, dei Corrispettivi; (b)
    qualora ricorrano ragioni di sicurezza e/o riservatezza; (c) in caso di violazione, da parte del Cliente e/o di
    ciascun Utente e/o di ciascuna Controllata, agli obblighi di legge in materia di utilizzo dei servizi informatici e
    della rete internet; (d) nel caso in cui si verifichino problematiche all&rsquo;Infrastruttura Cloud e/o al Software
    che non siano rimediabili senza sospendere il relativo accesso, ivi inclusa l&rsquo;ipotesi di relativa sostituzione
    e/o migrazione anche parziale, in ogni caso previo avviso scritto al Cliente circa le ragioni della sospensione e le
    tempistiche di intervento previste.
  </p>
  <h3 id="h.5r5ge8szdni">15. DURATA, DISDETTA DEL SERVIZIO E POLICY DI RIMBORSO (CD. REFUND POLICY)</h3>
  <p>
    Il Contratto rimarr&agrave; efficace tra le Parti per 12 (dodici) mesi a partire dalla data del suo perfezionamento
    e si intender&agrave; automaticamente rinnovato alla scadenza per successivi periodi di 12 mesi (dodici) mesi
    ciascuno, sino alla data eventuale della disdetta della Societ&agrave; da comunicare in qualsiasi momento o
    attraverso la procedura dedicata per la disdetta e/o recesso del Servizio all&rsquo;interno dell&rsquo;applicazione
    o attraverso la procedura dedicata di Paddle.
  </p>
  <p>
    In ogni caso il Fornitore si impegna ad inviare all&rsquo;indirizzo email indicato dalla Societ&agrave; alla
    registrazione al Servizio allo scadere di ciascun anno di erogazione del Servizio a far data dal perfezionamento del
    Contratto una comunicazione circa la facolt&agrave; di disdetta.
  </p>
  <p>
    In Caso di disdetta la Societ&agrave; continuer&agrave; ad avere accesso al Servizio fino alla fine del periodo di
    fatturazione relativo.
    <u
      >I pagamenti non sono rimborsabili n&eacute; integralmente n&eacute; per periodi di abbonamento parzialmente
      utilizzati n&eacute; in caso di Servizio non fruito, ad eccezione del caso in cui il Fornitore eserciti il proprio
      diritto di recesso per motivi diversi rispetto a quelli di esercizio della clausola risolutiva espressa e di
      interdizione dal Servizio meglio specificati all&rsquo;art. 17; solo in quest&rsquo;ultimo caso la Societ&agrave;
      avr&agrave; diritto alla restituzione della quota di corrispettivo per il periodo di mancata fruizione dei
      Servizio, qualora essa sia gi&agrave; stata versata.</u
    >
  </p>
  <p>
    In caso di disdetta del Servizio, l&rsquo;account relativo verr&agrave; chiuso automaticamente al termine del
    periodo di fatturazione corrente. Se la registrazione al Servizio &egrave; stata effettuata usando come Metodo di
    pagamento l&rsquo;account con una terza parte per disdire il Servizio, potrebbe essere necessario dover disdire
    l&#39;abbonamento tramite tale terza parte, ad esempio accedendo alle impostazioni del proprio account con tale
    terza parte e disattivando l&#39;opzione di rinnovo automatico o annullando il Servizio medesimo attraverso tale
    terza parte.
  </p>
  <h3 id="h.a75js9yty8wg">16. RECESSO</h3>
  <p>
    16.1. Le Parti riconoscono il reciproco diritto di recedere dal Contratto come di seguito rispettivamente precisato.
  </p>
  <p>
    La Societ&agrave; ha il diritto di recedere dal Contratto, in qualsiasi momento inviando una comunicazione o
    attraverso la procedura dedicata per la disdetta e/o recesso del Servizio all&rsquo;interno dell&rsquo;applicazione
    o attraverso la procedura dedicata di Paddle tenendo indenne il Fornitore delle spese sostenute, dei lavori
    eseguiti.
  </p>
  <p>Nello specifico, dalla data di efficacia della comunicazione:</p>
  <p>&#9679; il Fornitore dovr&agrave; cessare tutte le prestazioni contrattuali;</p>
  <p>
    &#9679; il Fornitore ha diritto al pagamento di quanto eseguito secondo il corrispettivo e le condizioni di
    contratto.
  </p>
  <p>
    il Fornitore ha il diritto di recedere dal Contratto in qualsiasi momento o attraverso la procedura dedicata per la
    disdetta del Servizio all&rsquo;interno dell&rsquo;applicazione o attraverso la procedura dedicata di Paddle.
  </p>
  <p>
    16.2. Nel caso in cui il Fornitore eserciti il proprio diritto di recesso per motivi diversi rispetto a quelli di
    cui al paragrafo che segue, la Societ&agrave; avr&agrave; diritto alla restituzione della quota di corrispettivo per
    il periodo di mancata fruizione dei Servizio, qualora essa sia gi&agrave; stata versata.
  </p>
  <h3 id="h.2ihff4gkk372">17. CLAUSOLA RISOLUTIVA ESPRESSA E INTERDIZIONE DAL SERVIZIO</h3>
  <p>
    17.1. Fatto salvo il risarcimento del danno, il Fornitore si riserva il diritto di risolvere il Contratto ai sensi
    dell&rsquo;art. 1456 c.c. a seguito di invio di semplice comunicazione scritta all&rsquo;indirizzo email indicato
    dalla Societ&agrave; ovvero, a mezzo di una comunicazione formale quale lettera via posta elettronica certificata
    (PEC) e/o lettera raccomandata A/R, in caso di mancato adempimento da parte della Societ&agrave; e/o di ciascun
    Utente anche di una sola delle previsioni: 3 (Obblighi del Cliente), 4 (Credenziali di Accesso), 6 (Corrispettivo,
    Modalit&agrave; e termini di pagamento, Rivenditore del Servizio), 8 (Propriet&agrave; Intellettuale), 9
    (Responsabilit&agrave; e dichiarazioni del Cliente), 10 (Ritiro dal mercato e sostituzione), 11 (Manleva), 15 (
    Durata, Disdetta del servizio e Policy di rimborso (cd. Refund Policy)) e 21 (Cessione del contratto).
  </p>
  <p>
    17.2. Fermo restando l&rsquo;obbligo per la Societ&agrave; di versare i Corrispettivi di cui all&rsquo;articolo 6,
    il Fornitore si riserva altres&igrave; la facolt&agrave; di interrompere in ogni momento la fornitura del Servizio
    in caso di: (i) inadempimento del Cliente e/o di ciascun Utente ad una delle obbligazioni di cui al paragrafo 17.1;
    (ii) inadempimento di una delle obbligazioni assunte dalla Societ&agrave; in uno qualsiasi degli eventuali ulteriori
    contratti conclusi tra il medesimo Cliente e il Fornitore, obbligazioni il cui inadempimento costituisca causa di
    risoluzione di tali eventuali ulteriori contratti. In tali ipotesi, il Fornitore comunicher&agrave;
    l&rsquo;intenzione di interrompere la fornitura del Servizio, invitando la Societ&agrave;, ove possibile, a porre
    rimedio all&rsquo;inadempimento entro un determinato termine.
  </p>
  <p>18. EFFETTI DELLA CESSAZIONE DEL CONTRATTO E RESTITUZIONE VERSIONE</p>
  <p>
    18.1. In caso di cessazione del Contratto, per qualsiasi causa intervenuta, il Fornitore cesser&agrave;
    immediatamente e definitivamente la fornitura del Servizio.
  </p>
  <p>
    18.2. Fatti salvi diversi accordi fra le Parti, il Fornitore provveder&agrave; a cancellare in maniera permanente i
    dati della Societ&agrave;, entro 30 (trenta) giorni dalla cessazione del Contratto nel rispetto degli obblighi di
    legge.
  </p>
  <p>
    18.3. Resta in ogni caso inteso che le seguenti previsioni sopravvivranno alla cessazione del Contratto, per
    qualsiasi causa intervenuta: 1 (Ambito di applicazione delle Condizioni Generali), 3(Obblighi del Cliente), 6.
    (Corrispettivo, Modalit&agrave; e Termini di Pagamento, Rivenditore Del Servizio), 8 (Propriet&agrave;
    Intellettuale), 9 (Responsabilit&agrave; e dichiarazioni del Cliente), 10 (Ritiro dal mercato e sostituzione dei
    prodotti), 11 (Manleva), 12 (Responsabilit&agrave; del Fornitore), 15 (Durata, disdetta del servizio e Policy di
    Rimborso (Cd. refund Policy)), 19 (Comunicazioni), 20 (Legge applicabile, Foro esclusivo e disposizioni
    applicabili), 21 (Cessione del Contratto).
  </p>
  <h3 id="h.uytxj05j8xd4">19. COMUNICAZIONI</h3>
  <p>
    Tutte le comunicazioni alla Societ&agrave; inerenti al Contratto potranno essere effettuate all&rsquo;indirizzo
    email comunicato dalla Societ&agrave;. Il Cliente avr&agrave; la responsabilit&agrave; di comunicare ogni variazione
    del proprio indirizzo email identificato per tutte le comunicazioni.
  </p>
  <h3 id="h.7asp5nh401av">20. LEGGE APPLICABILE, FORO ESCLUSIVO E DISPOSIZIONI FINALI</h3>
  <p>
    20.1. Il presente Contratto &egrave; regolato e deve essere interpretato in conformit&agrave; alla legge italiana.
  </p>
  <p>
    20.2 Il presente Contratto sostituisce ogni precedente pattuizione fra le Parti contenuta in preesistenti accordi
    orali o scritti, relativi all&#39;esecuzione del presente Contratto.
  </p>
  <p>
    20.3 Se una o pi&ugrave; clausole del presente Contratto vengono colpite da nullit&agrave; o se vengono rese
    inapplicabili dall&rsquo;effetto della legge o da una decisione che si impone alle Parti, questo non avr&agrave;
    l&rsquo;effetto di causare la nullit&agrave; dell&rsquo;insieme del presente Contratto, n&eacute; di alterare la
    validit&agrave; ed il carattere obbligatorio dell&rsquo;insieme delle altre clausole.
  </p>
  <p>
    20.4 Le presenti Condizioni Generali potranno essere modificate e/o integrate unilateralmente dal Fornitore, fermo
    restando il diritto al Recesso della Societ&agrave;. Continuando ad utilizzare il Servizio dopo l&#39;entrata in
    vigore di eventuali modifiche, l&#39;utente accetta di essere vincolato da tali modifiche.
  </p>
  <p>
    20.5 L&rsquo;eventuale omissione di far valere uno o pi&ugrave; dei diritti previsti dal Contratto non potr&agrave;
    comunque essere intesa come definitiva rinuncia a tali diritti e non impedir&agrave;, quindi, di esigerne in
    qualsiasi altro momento il puntuale e rigoroso adempimento.
  </p>
  <p>
    20.6 Qualunque controversia in ordine all&#39;interpretazione e/o all&rsquo;esecuzione del presente Contratto
    sar&agrave; devoluta alla competenza del Foro di Milano.
  </p>
  <h3>21. CESSIONE DEL CONTRATTO</h3>
  <p>
    Salva preventiva autorizzazione scritta del Fornitore, &egrave; fatto divieto alla Societ&agrave; di cedere, in
    tutto o in parte, il Contratto.
  </p>
  <h3>22. TRATTAMENTO DATI PERSONALI</h3>
  <p>
    22.1. Le Parti riconoscono e si danno reciprocamente atto che la sottoscrizione del presente Contratto e
    l&rsquo;esecuzione del Servizio comporteranno la raccolta e il trattamento di dati personali del Destinatario del
    Servizio (nonch&eacute; di parti ad essi correlate, quali procuratori, legali rappresentanti, etc.) da parte del
    Fornitore per le finalit&agrave; necessarie all&rsquo;esecuzione del predetto Contratto ed in conformit&agrave; alla
    Legislazione in materia di protezione dei Dati Personali e alle altre eventuali previsioni di legge applicabili. Il
    Fornitore, in qualit&agrave; di titolare del trattamento, si impegna a trattare tali dati secondo quanto riportato
    nell&#39;informativa rilasciata dal Fornitore ai sensi dell&rsquo;articolo 13 del GDPR e disponibile sul sito web
    del Servizio
    <a href="https://spaceship-app.nativatools.com/privacy-policy"
      >https://spaceship-app.nativatools.com/privacy-policy</a
    >
  </p>
  <p>
    22.2. Resta inteso che la Societ&agrave; rispetto ai Dati Personali di Terzi, eventualmente immessi rester&agrave;
    pienamente responsabile dell&rsquo;adempimento nei confronti degli interessati di tutti gli obblighi previsti dal
    GDPR e dalla Legislazione in materia di protezione dei Dati Personali ad esso applicabili in qualit&agrave; di
    titolare del trattamento. Il Fornitore non assume alcuna responsabilit&agrave; in merito alle conseguenze derivanti
    dall&rsquo;inosservanza da parte del Cliente degli obblighi sul medesimo gravanti in qualit&agrave; di titolari del
    trattamento.
  </p>
  <hr />
</div>
