import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  styles: [ 'footer { height: 52px}'],
  template: `
    <footer class="bg-dark d-flex gap-1 justify-content-center align-items-center px-2 text-center">
      <div>
        @if (currentLang === 'it') {
          <a class="text-primary" [routerLink]="['/', 'privacy-policy']">{{'footer.component.privacy-policy' | translate}}</a>
        } @else {
            <a class="text-primary" [routerLink]="['/', 'privacy-policy-en']">{{'footer.component.privacy-policy' | translate}}</a>
        }
      </div>
      <div>
        <span class="mx-2 d-none d-md-inline">|</span>
        @if (currentLang === 'it') {
          <a class="text-primary" [routerLink]="['/', 'terms-and-conditions']">{{ 'footer.component.terms-and-conditions' | translate }}</a>
        }  @else {
          <a class="text-primary" [routerLink]="['/', 'terms-and-conditions-en']">{{ 'footer.component.terms-and-conditions' | translate }}</a>
        }
      </div>
      <div>
        <span class="mx-2 d-none d-md-inline">|</span>
        <a class="link text-primary" [href]="cookiePolicy" target="_blank">Cookie Policy</a>
      </div>
    </footer>
  `,
})
export class FooterComponent {
  constructor(private translateSvc: TranslateService) {}

  get currentLang(): string {
    return this.translateSvc.currentLang;
  }

  get cookiePolicy(): string {
    switch (this.translateSvc.currentLang) {
      case 'it': return 'https://www.iubenda.com/privacy-policy/29806652/cookie-policy';
      case 'en': return 'https://www.iubenda.com/privacy-policy/35028870/cookie-policy';
      case 'pt': return 'https://www.iubenda.com/privacy-policy/92245127/cookie-policy';
      default: return 'https://www.iubenda.com/privacy-policy/29806652/cookie-policy';
    }
  }
}
