import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { isMobile, isNotMobile } from './actions';

export const layoutFeatureKey = 'layout';

export interface LayoutState {
  isMobile: boolean;
}

const initialState: LayoutState = {
  isMobile: false,
};

export const reducer = createReducer(
  initialState,
  on(isMobile, (): LayoutState => ({ isMobile: true })),
  on(isNotMobile, (): LayoutState => ({ isMobile: false }))
);

export const selectLayoutState = createFeatureSelector<LayoutState>(layoutFeatureKey);

export const selectViewportState = createSelector(selectLayoutState, (state: any) => state.isMobile);
