import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentComponent } from '@core/components/payment/payment.component';
import { PrivacyPolicyComponent } from '@core/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '@core/components/terms-and-conditions/terms-and-conditions.component';
import { DisallowNavigationToNativaGuard } from '@core/guards/disallow-navigation-to-nativa.guard';
import { NewsDetailsComponent } from '@shared/components/news/components/news-details/news-details.component';
import { ActivateAccountComponent } from './auth/components/activate-account/activate-account.component';
import { CheckYourEmailComponent } from './auth/components/check-your-email/check-your-email.component';
import { CheckoutComponent } from './auth/components/checkout/checkout.component';
import { PwdResetRequestComponent } from './auth/components/pwd-reset-request/pwd-reset-request';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { SigninComponent } from './auth/components/signin/signin.component';
import { AppErrorPageComponent } from './core/components/app-error-page/app-error-page.component';
import { FooterWrapperComponent } from './core/components/footer-wrapper/footer-wrapper.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { UserProfileComponent } from './core/components/user-profile/user-profile.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ExceptNativaRoleGuard } from './core/guards/except-nativa-role.guard';
import { HasNativaRoleGuard } from './core/guards/has-nativa-role.guard';
import { LogoutIfAuthenticatedGuard } from './core/guards/logout-if-authenticated.guard';
import { RedirectIfAuthenticatedGuard } from './core/guards/redirect-if-authenticated.guard';
import { FaqComponent } from './faq/faq.component';
import { NewsComponent } from './shared/components/news/news.component';
import { PrivacyPolicyEnComponent } from '@core/components/privacy-policy/privacy-policy-en.component';
import { TermsAndConditionsEnComponent } from '@core/components/terms-and-conditions/terms-and-conditions-en.component';

const routes: Routes = [
  {
    path: 'signin',
    component: LayoutComponent,
    children: [
      {
        canActivate: [RedirectIfAuthenticatedGuard],
        path: '',
        component: SigninComponent,
      },
      {
        canActivate: [LogoutIfAuthenticatedGuard],
        path: 'reset',
        component: ResetPasswordComponent,
      },
      {
        canActivate: [LogoutIfAuthenticatedGuard],
        path: 'activate',
        component: ActivateAccountComponent,
      },
      {
        path: 'check-your-email',
        component: CheckYourEmailComponent,
      },
    ],
  },
  {
    path: 'checkout',
    canActivate: [LogoutIfAuthenticatedGuard],
    component: CheckoutComponent,
  },
  {
    path: 'signin/reset-request',
    component: PwdResetRequestComponent,
  },
  {
    path: 'not-found',
    component: FooterWrapperComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: 'error',
    component: FooterWrapperComponent,
    children: [
      {
        path: '',
        component: AppErrorPageComponent,
      },
    ],
  },
  {
    path: 'privacy-policy',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyComponent,
      },
    ],
  },
  {
    path: 'privacy-policy-en',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyEnComponent,
      },
    ],
  },
  {
    path: 'terms-and-conditions',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: TermsAndConditionsComponent,
      },
    ],
  },
  {
    path: 'terms-and-conditions-en',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: TermsAndConditionsEnComponent,
      },
    ],
  },
  {
    path: 'payment',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: PaymentComponent,
      },
    ],
  },
  {
    path: 'app',
    redirectTo: '/',
    pathMatch: 'prefix',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [DisallowNavigationToNativaGuard],
      },
      {
        path: 'management',
        canActivate: [HasNativaRoleGuard],
        loadChildren: () => import('./admin/management/management.module').then(m => m.ManagementModule),
      },
      {
        path: 'profile',
        component: UserProfileComponent,
      },
      {
        path: 'faq',
        component: FaqComponent,
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: ':tenant',
        canActivate: [ExceptNativaRoleGuard],
        children: [
          {
            path: 'news',
            component: NewsComponent,
          },
          {
            path: 'news/detail/:newsId',
            component: NewsDetailsComponent,
          },
          {
            path: 'notifications',
            loadChildren: () =>
              import('./user-notifications/user-notifications.module').then(m => m.UserNotificationsModule),
          },
          {
            path: 'profile',
            loadChildren: () => import('./tenant-profile/tenant-profile.module').then(m => m.TenantProfileModule),
          },
          {
            path: 'subscription',
            loadChildren: () =>
              import('./tenant-subscriptions/tenant-subscriptions.module').then(m => m.TenantSubscriptionsModule),
          },
          {
            path: 'flows',
            loadChildren: () => import('./flows/flows.module').then(m => m.FlowsModule),
          },
          {
            path: 'users',
            loadChildren: () => import('./tenant-users/tenant-users.module').then(m => m.TenantUsersModule),
          },
          {
            path: 'archive',
            loadChildren: () => import('./archive/archive.module').then(m => m.ArchiveModule),
          },
          {
            path: '',
            redirectTo: '/not-found',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
