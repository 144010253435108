<div class="mt-4 surface-bg">
  <!-- <h1 class="text-center py-5">{{ 'NAV.PROFILE' | translate }}</h1> -->
  @if (currentUser$ | async; as user) {
    <section class="row justify-content-center">
      <div class="col-12 col-md-6">
        <app-user-details
          [user]="user"
          [isUserView]="true"
          (formStateChange)="onFormStateChange($event)"
          (formValueChange)="onFormValueChange($event)"></app-user-details>
      </div>
    </section>
    <div class="d-flex justify-content-center my-4">
      <button mat-raised-button color="primary" class="w-25" [disabled]="!canSave" (click)="updateUserProfile(user)">
        {{ 'user-profile.component.salva' | translate }}
      </button>
    </div>
    <div class="d-flew justify-content-center align-items-center">
      <p class="text-center mb-0">
        {{ 'PROFILE.CHANGE_PASSWORD' | translate }}
        <button
          style="padding-bottom: 0.6em"
          class="btn btn-link text-1 mx-0 px-0 pb-2 pointer changePasswordBtn d-inline-block"
          (click)="pwdReset(user)">
          {{ 'CLICK_HERE' | translate }}
        </button>
      </p>
    </div>
  }
</div>
