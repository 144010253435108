<div class="container">
  <div>
    <p></p>
  </div>
  <p></p>
  <h1>INFORMATIVA PRIVACY Spaceship</h1>
  <p>
    INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DEGLI ARTT. 12, 13, 14 DEL GENERAL DATA PROTECTION
    REGULATION (GDPR)
  </p>
  <i class="text-muted my-2"
    >Quanto contenuto in questo documento &egrave; da considerarsi opera dell&rsquo;ingegno protetta dal diritto
    d&#39;autore e dalle leggi nazionali, comunitarie e internazionali che sono applicabili. I diritti di utilizzo, i
    diritti di traduzione, di riproduzione e di adattamento totale o parziale e con qualsiasi mezzo, sono riservati per
    tutti i Paesi. Tali condizioni sono implicitamente accettate con la lettura del presente documento e si applicano
    sia alla versione digitale che a qualunque sua riproduzione.</i
  >
  <p></p>
  <p>Versione di aprile 2024</p>
  <p></p>
  <p>
    Il Regolamento (UE) 2016/679 (&laquo;Regolamento generale sulla protezione dei dati&raquo;), di seguito GDPR,
    prevede la tutela delle persone fisiche con riferimento al trattamento dei dati personali.
  </p>
  <p>
    Nativa, si impegna affinch&eacute; il trattamento dei dati riferibili ad una persona fisica (di seguito
    &ldquo;Interessato&rdquo;) sia improntato ai principi di correttezza, liceit&agrave; e trasparenza, nonch&eacute; di
    tutela della riservatezza e dei diritti dell&rsquo;interessato stesso.
  </p>
  <p>
    Nativa proceder&agrave; al trattamento dei Tuoi dati personali nel rispetto della normativa, con la massima cura,
    attuando procedure e processi di gestione efficaci per garantire la tutela del trattamento dei dati personali per i
    quali hai prestato il consenso, impegnandosi ad adottare misure adeguate, in modo tale da evitare accessi o
    divulgazioni non autorizzate, nonch&eacute; per mantenere l&rsquo;accuratezza dei dati e per garantirne un uso
    appropriato degli stessi.
  </p>
  <p>
    Ai sensi degli artt. 13 e 14 del Regolamento UE 679/2016 (GDPR) (di seguito &ldquo;Regolamento&rdquo;)&nbsp;e ai
    sensi dell&rsquo;art. 13 del D.Lgs. 30 giugno 2003 n. 196 &ldquo;Codice in materia di protezione dei dati
    personali&rdquo;, come modificato ed integrato dal D.Lgs. 10 agosto 2018 n 101, Ti forniamo, in coerenza al
    principio di trasparenza, le seguenti informazioni al fine di renderti consapevole prima di compiere materialmente
    l&rsquo;accesso, quale utente del Servizio denominato &ldquo;Spaceship&rdquo; (di seguito anche il
    &ldquo;Servizio&rdquo;) raggiungibile ai seguenti indirizzi :
    <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a>&nbsp; per il sito web &nbsp;e
    <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>&nbsp;per
    l&rsquo;applicazione web, delle caratteristiche dei dati oggetto di trattamento e delle modalit&agrave; del
    trattamento stesso in caso di loro conferimento.
  </p>
  <h3>1 - IDENTIT&Agrave; E DATI DI CONTATTO</h3>
  <p>Il &quot;Titolare&quot; del trattamento &egrave;: &nbsp;Nativa S.R.L. Societ&agrave; Benefit</p>
  <p>Sede legale: Piazzale Clodio, 22 - 00195 - Roma</p>
  <p>indirizzo email: <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>&nbsp;</p>
  <p>
    casella di posta certificata (PEC):
    <a href="mailto:nativasrl@legalmail.it">nativasrl&#64;legalmail.it</a>&nbsp;&nbsp;
  </p>
  <h3>2 - QUALI DATI TRATTIAMO</h3>
  <p>
    Le informazioni che Ti riguardano quale Interessato e che sono oggetto di trattamento nel caso del Tuo conferimento
    sono:
  </p>
  <ul>
    <li>
      i dati di navigazione: i sistemi informatici acquisiscono, nel corso dell&rsquo;utilizzo della piattaforma e per
      il suo normale utilizzo, dati che per loro natura non hanno funzione identificativa, ma che potrebbero assumerla
      in limitati casi qualora elaborati od associati a dati in possesso di soggetti terzi. Rientrano in questa
      categoria, ad esempio, gli indirizzi IP, gli indirizzi URI (Uniform Resource Identifier) delle risorse richieste,
      come ad esempio l&rsquo;orario delle richieste, le caratteristiche del dispositivo utilizzato, le dimensioni dei
      file scambiati o altre tipologie di informazioni. Si tratta di dati anonimi o aggregati che ordinariamente non
      permettono la tua identificabilit&agrave; e servono per identificare anomalie e problematiche. Il sito utilizza
      anche cookies tecnici ed analitici, implementati secondo le previsioni normative in vigore. Per maggiori dettagli
      si rimanda alla Cookie Policy.
    </li>
    <li>
      i dati anagrafici quale utente semplice forniti durante la compilazione del form di contatto del sito di Nativa o
      della richiesta di informazioni alla email di contatto ad es: il nome, il cognome, l&rsquo;azienda di tua
      eventuale appartenenza e/o di riferimento, la sede dell&rsquo;Azienda e l&rsquo;indirizzo e-mail di contatto;
    </li>
    <li>
      i dati anagrafici quale utente abilitato al Servizio&nbsp;quali ad es: nome, cognome, email, data di nascita,
      numero di telefono, possibilit&agrave; di aggiungere altre informazioni a discrezione dell&rsquo;utente.
    </li>
  </ul>
  <p>
    NATIVA non raccoglie e non riceve i dati cd. particolari&nbsp;come definiti all&rsquo;art. 9 del GDPR ovvero dati
    che che rivelino l&#39;origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o
    l&#39;appartenenza sindacale, nonch&eacute; trattare dati genetici, dati biometrici intesi a identificare in modo
    univoco una persona fisica, dati relativi alla salute o alla vita sessuale o all&#39;orientamento sessuale della
    persona.&nbsp;Pertanto l&rsquo;inserimento in alcuno dei campi riservati agli utenti abilitati della piattaforma
    spaceship, di dati riconducibili a tale categoria sar&agrave; di Tua esclusiva responsabilit&agrave; quale
    Interessato e, saranno trattati solo ove conferiti dall&rsquo;Interessato esprimendo un consenso esplicito o se resi
    manifestamente pubblici all&rsquo;interno del profilo dell&rsquo;Interessato, in ogni caso il Titolare si riserva il
    diritto di cancellazione.
  </p>
  <p>
    Inoltre utilizzando il nostro servizio spaceship quale Utente potresti comunicare dati personali di terzi o
    condividere documenti contenenti dati personali di terzi. In qualit&agrave; di Utente Ti assumi la
    responsabilit&agrave; dei Dati Personali di terzi pubblicati o condivisi mediante la compilazione dei campi di
    spaceship e garantisci di avere il diritto di comunicarli o diffonderli, liberando il Titolare del trattamento da
    qualsiasi responsabilit&agrave; verso terzi. In qualit&agrave; di Utente Ti assumi altres&igrave; la
    responsabilit&agrave; della veridicit&agrave; dei Dati e delle informazioni fornite nella compilazione del Form e
    nella registrazione al servizio.
  </p>
  <p>I trattamenti dei dati personali che Ti sono richiesti sono effettuati per finalit&agrave;:</p>
  <ol start="1">
    <li>
      connesse e strumentali alla gestione delle tue richieste&nbsp;di informazioni e di contatto, oltre che
      l&rsquo;adempimento di ogni altro obbligo conseguente.
    </li>
    <li>
      connesse e strumentali all&rsquo;attivazione ed al funzionamento dei servizi e/o dei prodotti relativi alla
      piattaforma spaceship forniti da Nativa sulla base di tua richiesta.
    </li>
    <li>
      di legittimo interesse di NATIVA, ad esempio: &nbsp;per l&#39;analisi e il miglioramento dei propri servizi; per
      lo svolgimento di attivit&agrave; statistiche e/o di ricerca e sviluppo di mercato, anche mediante analisi dei
      dati provenienti dall&rsquo;utilizzo dei servizi; per difendere i propri diritti nel corso dello svolgimento di
      procedimenti giudiziari, amministrativi o stragiudiziali, e nell&#39;ambito di controversie sorte in relazione ai
      servizi offerti; nel caso di operazioni straordinarie d&rsquo;azienda.
    </li>
    <li>
      di adempimento agli obblighi di legge cui NATIVA &egrave; tenuta in funzione dell&rsquo;attivit&agrave;
      esercitata.
    </li>
  </ol>
  <h4>2.1. - COME RACCOGLIAMO I DATI</h4>
  <p>I Dati oggetto di attivit&agrave; di trattamento da parte di Nativa potrebbero essere acquisiti:</p>
  <p></p>
  <p>
    &bull; &nbsp;presso l&rsquo;Interessato, anche attraverso le tecniche di comunicazione a distanza delle quali si
    avvale ad esempio i dati acquisiti dalla compilazione da parte dell&rsquo;Interessato dal form di contatto presente
    sul sito internet <a href="https://nativalab.com">https://nativalab.com/</a>&nbsp;ed in genere ai dati acquisiti
    attraverso la navigazione stessa del sito per il quale si rimanda alla relativa Informativa sulla Privacy policy, i
    dati acquisiti dalla compilazione da parte dell&rsquo;Interessato dal form presente sul sito internet
    <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a>&nbsp;e/o attraverso la casella di
    posta <a href="mailto:info@nativalab.com">info&#64;nativalab.com</a>&nbsp;oppure
    <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <p>
    Per le finalit&agrave; proprie della piattaforma Spaceship di guida e di supporto per le Societ&agrave; &nbsp;per il
    percorso di sostenibilit&agrave;&nbsp;finalizzato anche alla certificazione B Corp e all&rsquo;adozione dello status
    di Societ&agrave; Benefit, e per offrire informazioni sulle Societ&agrave; Benefit, sulle B Corp e sulla
    sostenibilit&agrave; in generale saranno altres&igrave; utilizzati i Dati provenienti da fonti pubbliche, come
    pubblici registri, elenchi,&nbsp;documenti conoscibili da chiunque (es. i dati contenuti nello statuto delle aziende
    e pubblicati su siti internet liberamente accessibili) bilanci, informazioni contenute nel registro delle imprese
    presso le Camere di commercio), nonch&eacute; i Dati estratti da fonti pubblicamente e generalmente accessibili da
    chiunque, come le testate giornalistiche cartacee o digitali, le informazioni attinte da siti web di enti pubblici o
    di altre autorit&agrave; di vigilanza e controllo.
  </p>
  <p>
    Inoltre per le finalit&agrave; proprie della piattaforma Spaceship potranno essere utilizzati i &nbsp;dati relativi
    alle finalit&agrave; specifiche di beneficio comune dell&rsquo;azienda che pur riferendosi direttemente
    all&rsquo;Azienda potrebbero trattare dati personali a Te riferibili direttamente e/o indirettamente; tali dati
    potranno essere pubblicati sul sito societ&agrave;benefit.net
  </p>
  <p></p>
  <p>
    I dati relativi alle finalit&agrave; specifiche di beneficio comune dell&rsquo;azienda sono dati che vengono salvati
    nel database di Spaceship nel flow Societ&agrave; benefit e verranno utilizzati dall&rsquo;Azienda cui si
    riferiscono per modificare il proprio statuto.
  </p>
  <h4>2.2. - COME TRATTIAMO I DATI</h4>
  <p>
    NATIVA Ti informa che non commercializza i Tuoi dati personali n&eacute; li utilizza per finalit&agrave; non
    dichiarate.
  </p>
  <p>
    Nativa tratta i Dati Personali degli Interessati adottando le opportune misure di sicurezza volte ad impedire
    l&rsquo;accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.
  </p>
  <p>
    Il trattamento dei dati personali potr&agrave; avvenire mediante supporto cartaceo e/o con l&rsquo;ausilio di
    strumenti informatici, collegati o meno in rete, secondo le finalit&agrave; dichiarate e, comunque, in modo da
    garantire la sicurezza e la riservatezza dei dati stessi, con modalit&agrave; organizzative e con logiche
    strettamente correlate alle finalit&agrave; indicate.&nbsp;Oltre al Titolare, in alcuni casi, potrebbero avere
    accesso ai Dati categorie di incaricati coinvolti nella gestione della relazione di impatto (personale
    amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come ad es:
    fornitori di servizi tecnici terzi, hosting provider, societ&agrave; informatiche, agenzie di comunicazione)
    nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare.
  </p>
  <p>
    Qualora il titolare del trattamento intenda trattare ulteriormente i dati personali per una finalit&agrave; diversa
    da quella per cui essi sono stati raccolti, prima di tale potenziale e ulteriore trattamento, fornir&agrave;
    all&#39;interessato informazioni in merito a tale diversa finalit&agrave; e ogni ulteriore informazione pertinente.
  </p>
  <p>I destinatari dei dati sono indicati nello specifico all&rsquo;art. 4.</p>
  <h3>3 - TIPOLOGIA DI DATI TRATTATI, FINALIT&Agrave; DEL TRATTAMENTO, BASE GIURIDICA E PERIODO DI CONSERVAZIONE</h3>
  <p>NATIVA tratta i dati in base ai principi e alle norme del GDPR.</p>
  <p>
    I Dati Personali possono essere forniti da te liberamente fatto salvo quanto specificato rispetto ai dati raccolti
    automaticamente e in modo anonimizzato durante l&#39;uso dei Siti Web di propriet&agrave; di NATIVA e la piattaforma
    di spaceship.
  </p>
  <p>
    NOTA BENE: Con l&#39;uso o la consultazione dei siti Web di titolarit&agrave; di NATIVA approvi esplicitamente quale
    interessato la relativa informativa privacy e acconsenti al trattamento dei tuoi dati personali in relazione alle
    modalit&agrave; e alle finalit&agrave; descritte.
  </p>
  <p>
    La comunicazione dei dati costituisce requisito essenziale per l&rsquo;adempimento degli obblighi precontrattuali
    e/o contrattuali. La mancata comunicazione dei dati personali avr&agrave; come conseguenza
    l&rsquo;impossibilit&agrave; di fornire i servizi richiesti, potendo risultare compromessa anche la navigazione del
    sito stesso.
  </p>
  <p>
    E&rsquo; comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun
    trattamento.
  </p>
  <p>
    L&rsquo;utilizzo di Cookie - o di altri strumenti di tracciamento - da parte della Piattaforma spaceship o dei
    titolari dei servizi terzi utilizzati, ha la finalit&agrave; di fornire il Servizio richiesto dall&#39;Utente, oltre
    alle ulteriori finalit&agrave; descritte nel presente documento e nella Cookie Policy.
  </p>
  <p>
    Di seguito ti indichiamo ai sensi dell&rsquo;art. 6 del GDPR la finalit&agrave;, la base giuridica, relative al
    trattamento dei tuoi Dati:
  </p>
  <a></a><a></a>
  <div class="table-responsive">
    <table class="table table-dark">
      <tr>
        <td colspan="1" rowspan="1">
          <p>FINALIT&Agrave;</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>BASE LEGALE</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>SPIEGAZIONE</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Richieste di attivit&agrave; preliminari quali la richiesta di &nbsp;informazioni e di contatto.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Adempimento di misure precontrattuali di cui sei parte ex art. 6 (1) lett. b) GDPR</p>
          <p></p>
          <p>
            Consenso dell&rsquo;interessato (facoltativo, libero e revocabile in qualsiasi momento) &ndash; art. 6 (1)
            lett. a) GDPR.
          </p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Quando richiedi per mezzo di tuoi dati di contatto prestazioni inerenti alla richiesta di iscrizione, di
            informazioni e di contatto e/o di invio di materiale informativo nonch&eacute; di ogni &nbsp;altro obbligo
            derivante &nbsp; alla mail di contatto indicata nel sito di nativa o compilando il form dedicato e/o
            inviando una mail agli indirizzi <a href="mailto:info@nativalab.com">info&#64;nativalab.com</a>&nbsp;oppure
            <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>
          </p>
          <p></p>
          <p>
            Il conferimento dei dati &egrave; facoltativo, tuttavia l&rsquo;eventuale rifiuto dell&rsquo;Interessato a
            fornire i dati comporta l&rsquo;impossibilit&agrave; per il Titolare di dare seguito alla prestazione
            richiesta.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Registrazione, autenticazione, utilizzo della piattaforma spaceship come utente qualificato.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Adempimento del contratto di cui sei parte ex art. 6 n. 1 lett. b) GDPR.</p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            In esecuzione dell&rsquo;accordo di servizio relativo ad spaceship che hai con Nativa e che ti permette di
            usufruirne come utente qualificato.
          </p>
          <p>
            Il conferimento dei dati &egrave; facoltativo, tuttavia l&rsquo;eventuale rifiuto dell&rsquo;Interessato a
            fornire i dati comporta l&rsquo;impossibilit&agrave; per il Titolare di dare seguito alla prestazione
            richiesta.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Manutenzione e miglioramento del servizio</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legittimo interesse</p>
          <p></p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Riduciamo al minimo le possibilit&agrave; di accesso a dati personali nel caso di interventi sulla nostra
            piattaforma fatti salvi i termini e le condizioni dei servizi dei Terzi proprietari della infrastruttura ove
            &egrave; fisicamente ubicata la piattaforma spaceship; per cui si rinvia alle relative privacy policy.
            &nbsp;Poniamo in atto tutte le cautele tecniche e legali necessarie al rispetto dei tuoi diritti.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>
            Pianificazione di attivit&agrave; strategiche e gestionali, ricerche di mercato, e analisi di business
            intelligence e analisi statistica.
          </p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legittimo interesse</p>
          <p></p>
          <p>
            Consenso dell&rsquo;interessato (facoltativo, libero e revocabile in qualsiasi momento) &ndash; art. 6 (1)
            lett. a) GDPR.
          </p>
          <p></p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Nativa per il perseguimento del suo scopo sociale e il miglioramento delle azioni e dei servizi che offre
            alla sua clientela potrebbe &nbsp;eseguire attivit&agrave; di pianificazione strategica e gestionale anche
            attraverso analisi di business intelligence, ricerche di mercato, e la produzione di statistiche aggregate e
            anonime; tali attivit&agrave; verranno effettuate sulla base dei dati aziendali forniti e anche mediante
            analisi dei dati provenienti dall&rsquo;utilizzo dei servizi,&nbsp;
          </p>
          <p>
            ( nello specifico potrebbero essere utilizzati i &nbsp;dati relativi alle finalit&agrave; specifiche di
            beneficio comune dell&rsquo;azienda) che, pur riferendosi direttemente all&rsquo;Azienda potrebbero trattare
            dati personali a Te riferibili direttamente e/o indirettamente con ogni cautela legale e tecnica necessaria;
            tali dati potranno essere pubblicati sul sito societ&agrave;benefit.net; in ogni caso i dati personali
            trattati per fini statistici non saranno utilizzati per assumere decisioni o provvedimenti rivolti a singoli
            interessati, n&eacute; per il perseguimento di finalit&agrave; di altra natura.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Marketing generico della Titolare.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legittimo interesse della Titolare</p>
          <p></p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Potremo inviarti ad esempio, &nbsp;con modalit&agrave; automatizzate di contatto (e-mail), comunicazioni
            promozionali e commerciali relative a servizi/prodotti analoghi a quelli di cui hai gi&agrave; usufruito
            offerti dalla Titolare, a titolo esemplificativo e non esaustivo la proposta di rinnovo del Servizio
            spaceship o di webinar o di whitepaper, nonch&eacute; la realizzazione di studi di mercato e analisi
            statistiche .
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Comunicazioni commerciali</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Consenso dell&rsquo;interessato (facoltativo, libero e revocabile in qualsiasi momento) &ndash; art. 6 (1)
            lett. a) GDPR.
          </p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Previo Tuo consenso tratteremo i tuoi dati per informarti, anche attraverso strumenti automatizzati di
            contatto( e-mail, SMS, notifiche push e/o altri posizionamenti online, anche sui social network, telefono)
            sulle nostre iniziative commerciali, culturali, formative e di organizzazione di &nbsp;eventi, &nbsp;per
            svolgere ricerche di mercato &nbsp;e per l&rsquo;invio di materiale pubblicitario. Il trattamento di questi
            dati per questa finalit&agrave; potr&agrave; avvenire solo sulla base del Tuo consenso libero, specifico,
            informato e inequivocabile; il conferimento dei tuoi dati per questa finalit&agrave; non &egrave; necessario
            all&rsquo;erogazione dei servizi da te richiesti, tuttavia il mancato conferimento del consenso - o la sua
            revoca, ove inizialmente prestata - non ti consentir&agrave; di ricevere le comunicazioni elencate in questo
            capoverso. Ti ricordiamo che, in caso di mancato conferimento o revoca del consenso, potrai comunque
            ricevere comunicazioni commerciali e promozionali relative a prodotti e/o servizi analoghi a quelli da te
            gi&agrave; sottoscritti per i quali il trattamento si basa su un legittimo interesse del Titolare come
            indicato al punto precedente.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Profilazione</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Consenso dell&rsquo;interessato (facoltativo, libero e revocabile in qualsiasi momento) &ndash; art. 6 (1)
            lett. a) GDPR.
          </p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Previo Tuo consenso, tratteremo i Tuoi dati personali quale utente abilitato di spaceship, (anche attraverso
            tecnologie di tracciamento quali i cookie ove hai prestato il relativo consenso), per procedere ad
            attivit&agrave; di analisi ed elaborazione di informazioni a Te relative volte a definire un profilo delle
            Tue preferenze, abitudini, scelte di consumo e/o esperienze di navigazione. Tali attivit&agrave; di
            profilazione potranno essere condotte anche avvalendosi di strumenti messi a disposizione da terze parti.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Adempiere ad obblighi previsti da regolamenti e dalla normativa nazionale e sovranazionale applicabile.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Obbligo legale a cui &egrave; soggetta la Societ&agrave; &ndash; art. 6 (1) lett. c) GDPR.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Potremmo dover fornire i tuoi Dati ad autorit&agrave; giudiziarie o altre autorit&agrave; pubbliche in forza
            di obblighi di legge o richieste espresse, (ad es. la conservazione dei tuoi dati per finalit&agrave;
            amministrativo contabili).
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>
            Accertare, esercitare o difendere un diritto in sede giudiziaria o ogniqualvolta le autorit&agrave;
            giurisdizionali esercitino le loro funzioni giurisdizionali.
          </p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Obbligo legale art. 9, 2 lett. f) GDPR</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Potremo avere un interesse ad accertare e/o esercitare un diritto &nbsp;</p>
        </td>
      </tr>
    </table>
  </div>
  <p>
    <br />Decorsi i termini di conservazione, i dati saranno distrutti, cancellati o resi anonimi, compatibilmente con
    le procedure tecniche di cancellazione e di backup e con le esigenze di accountability della Titolare.
  </p>
  <h3>4 - DESTINATARI ED EVENTUALI CATEGORIE DI DESTINATARI DEI DATI PERSONALI</h3>
  <p>
    I Dati potranno essere conosciuti, in relazione allo svolgimento delle mansioni assegnate, dal personale di Nativa,
    ivi compresi i tirocinanti e i consulenti, tutti con autorizzazione al trattamento che operano sotto la diretta
    autorit&agrave; del Titolare , il quale li ha istruiti in tal senso.
  </p>
  <p>
    I dati potranno essere trattati da: Terzi Fornitori del Titolare per l&rsquo;erogazione di servizi connessi alla
    prestazione richiesta (quali ad es: assistenza, manutenzione, consegna/spedizione prodotti, erogazione di servizi
    aggiuntivi, fornitori di reti e servizi di comunicazione elettronica, societ&agrave; titolari di piattaforme
    digitali per la gestione, l&rsquo;aggiornamento e la conservazione dei dati, fornitori di servizi tecnici terzi,
    hosting provider ecc.); Professionisti/consulenti esterni e Societ&agrave; di consulenza; Istituti di credito e di
    pagamento digitale, Istituti bancari /postali; Amministrazione finanziaria, Enti pubblici, Autorit&agrave;
    Giudiziaria, Autorit&agrave; di vigilanza e controllo.
  </p>
  <p>
    I soggetti appartenenti a tali categorie operano in autonomia come distinti Titolari del trattamento o come
    soggetti&nbsp;qualificati come Responsabili del Trattamento nominati dal Titolare.
  </p>
  <p>
    I dati personali, in ogni caso, non saranno oggetto di diffusione e, pertanto, non saranno portati a conoscenza di
    soggetti indeterminati.
  </p>
  <h3>5 - PERIODO DI CONSERVAZIONE DEI DATI</h3>
  <p>
    I dati personali sono trattati per il tempo richiesto dalle finalit&agrave; per le quali sono stati raccolti secondo
    quanto &egrave; specificato nella tabella indicata all&rsquo;art. 3; dopo la cessazione di tale periodo i dati
    personali sono conservati per il periodo ordinario di prescrizione di 10 anni dalla raccolta ad eccezione del caso
    di esercizio di revoca del consenso da parte dell&rsquo;Interessato.
  </p>
  <p>
    In ogni caso a cadenza periodica potremo effettuare una revisione al fine di verificare se i dati personali che ci
    hai conferito debbano continuare ad essere trattati o meno &nbsp;e, in caso necessario Ti sottoporremo nuovamente
    &nbsp;la richiesta di rinnovo della prestazione del consenso.
  </p>
  <p>
    N.B.&nbsp;Per quanto riguarda i dati personali trattati per finalit&agrave; di Marketing generico descritte
    all&rsquo;art. 3 si specifica che:
  </p>
  <ul>
    <li>
      i Dati anagrafici e di contatto vengono conservati fino all&rsquo;esercizio del diritto di opposizione (&ldquo;opt
      out&rdquo;) contattando il Titolare con le modalit&agrave; descritte di seguito e,
    </li>
  </ul>
  <p>
    N.B.&nbsp;Per quanto riguarda i dati personali trattati per finalit&agrave; commerciali (Marketing diretto)
    descritte all&rsquo;art. 3 si specifica che:
  </p>
  <ul>
    <li>
      indipendentemente dalla pendenza di un servizio attivo, i dati personali saranno trattati per l&rsquo;invio di
      comunicazioni di natura commerciale fino alla revoca del Tuo consenso, fermo restando che decorsi 24
      (ventiquattro) mesi dalla Tua interazione con i nostri servizi i Tuoi dati non verranno pi&ugrave; trattati per
      tali finalit&agrave;.
    </li>
  </ul>
  <p>
    Resta fermo l&rsquo;esercizio di revoca del consenso da parte dell&rsquo;Interessato, quando il trattamento &egrave;
    basato sul consenso dell&rsquo;utente.
  </p>
  <p>
    Invero quando il trattamento &egrave; basato sul consenso dell&rsquo;Utente, il Titolare pu&ograve; conservare i
    Dati Personali pi&ugrave; a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe
    essere obbligato a conservare i Dati Personali per un periodo pi&ugrave; lungo in ottemperanza ad un obbligo di
    legge o per ordine di un&rsquo;autorit&agrave;.
  </p>
  <p>
    Decorsi i termini di conservazione sopra indicati, i Dati saranno distrutti, cancellati o resi anonimi,
    compatibilmente con le procedure tecniche di cancellazione e di backup e con le esigenze di accountability del
    Titolare.&nbsp;
  </p>
  <h3>6 - DIRITTI DELL&#39;INTERESSATO - RECLAMO ALL&rsquo;AUTORIT&Agrave; DI CONTROLLO</h3>
  <p>Si precisa che, in riferimento ai Tuoi dati personali, puoi esercitare i seguenti diritti:</p>
  <p>
    Puoi chiedere al Titolare l&rsquo;accesso ai dati che Ti riguardano, la loro cancellazione, la rettifica dei dati
    inesatti, l&rsquo;integrazione dei dati incompleti, la limitazione del trattamento nei casi previsti dall&rsquo;art.
    18 GDPR. Puoi esercitare questi diritti inviando un&rsquo;email all&rsquo;indirizzo
    <a href="mailto:impacto@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <p>
    Nel caso in cui il trattamento sia basato sul consenso o sul contratto e sia effettuato con strumenti automatizzati,
    potrai richiedere la portabilit&agrave; dei Tuoi dati ed avrai il diritto di riceverli in un formato strutturato, di
    uso comune e leggibile da dispositivo automatico, nonch&eacute;, se tecnicamente fattibile, di trasmetterli ad altro
    Titolare senza impedimenti.
  </p>
  <p>
    Hai il diritto di revocare il consenso prestato in qualsiasi momento per finalit&agrave; di marketing e/o di
    profilazione, nonch&eacute; di opporti al trattamento, per motivi connessi alla Tua situazione particolare, nelle
    ipotesi di legittimo interesse del Titolare.
  </p>
  <p>
    Hai il diritto di proporre reclamo all&#39;Autorit&agrave; di controllo competente nello Stato membro in cui risiedi
    abitualmente o lavori o dello Stato in cui si &egrave; verificata la presunta violazione (Per approfondimenti,
    consultare v. il sito istituzionale del Garante privacy
    <a href="http://www.garanteprivacy.it">www.garanteprivacy.it</a>)
  </p>
  <h4>6.1 - OPPOSIZIONE AL MARKETING GENERICO</h4>
  <p>
    Puoi in ogni momento decidere di non ricevere pi&ugrave; le comunicazioni relative alle attivit&agrave; di marketing
    generico contattattando in qualsiasi momento la Titolare scrivendo a
    <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <h3>7 - MODALIT&Agrave; E LUOGO DEL TRATTAMENTO</h3>
  <p>
    Il trattamento dei Tuoi dati personali pu&ograve; consistere in qualunque operazione o complesso di operazioni tra
    quelle indicate all&#39; art. 4, del GDPR.<br />I dati personali comunicati saranno conservati presso i nostri
    archivi cartacei e/o elettronici con logiche strettamente correlate alle finalit&agrave; indicate, adottando
    adeguate misure tecniche e organizzative volte alla tutela dei dati stessi. Il trattamento dei dati personali
    avverr&agrave; mediante l&rsquo;utilizzo di strumenti e procedure idonei a garantirne la sicurezza e la riservatezza
    e potr&agrave; essere effettuato, direttamente e/o tramite terzi delegati con cui il Titolare abbia stipulato
    apposite convenzioni, mediante l&rsquo;ausilio di mezzi informatici o strumenti elettronici<br />I dati personali da
    Te forniti saranno trattati presso la sede del Titolare ed in ogni altro luogo in cui le parti coinvolte nel
    trattamento siano localizzate.
  </p>
  <p>
    &Egrave; fatta salva la facolt&agrave; del Titolare di trasferire tali dati ad un Paese terzo o ad
    un&rsquo;organizzazione internazionale per il quale esiste una decisione di adeguatezza della Commissione Europea
    &nbsp;o, il riferimento alle garanzie appropriate o opportune e i mezzi per ottenere una copia di tali dati o il
    luogo dove sono stati resi disponibili (nel caso dei trasferimenti di cui all&#39;articolo 46 o 47, o
    all&#39;articolo 49, secondo comma GDPR); in questo caso Ti verr&agrave; data tempestiva comunicazione del
    trasferimento.
  </p>
  <h3>8 - MODIFICHE A QUESTA PRIVACY POLICY</h3>
  <p>
    Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque
    momento dandone informazione agli Utenti su questa pagina e, se possibile, su questo Sito Web nonch&eacute;, qualora
    tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di
    cui &egrave; in possesso il Titolare. Ti &nbsp;consigliamo &nbsp;dunque di consultare regolarmente questa pagina,
    facendo riferimento alla data di ultima modifica indicata in fondo. Qualora le modifiche interessino trattamenti la
    cui base giuridica &egrave; il consenso, il Titolare provveder&agrave; a raccogliere nuovamente il consenso
    dell&rsquo;Utente, se necessario.
  </p>
  <h3>9 -CONSENSO AL TRATTAMENTO DEI DATI&nbsp;</h3>
  <p>
    Ai sensi dell&rsquo;art. 6 comma 1 lett. a) GDPR, con la sottoscrizione del Servizio di cui l&rsquo;Utente &egrave;
    Parte e il compimento delle azioni successive quale Utente qualificato della piattaforma, l&rsquo;Utente
    &nbsp;manifesta il consenso al trattamento dei dati nell&rsquo;ambito delle finalit&agrave; e modalit&agrave; sopra
    richiamate, ove il consenso &egrave; &nbsp;richiesto per Legge: in particolare esprime il proprio consenso per la
    comunicazione dei dati a terzi cos&igrave; come indicati nella presente informativa e per le: Richieste di
    attivit&agrave; preliminari quali la richiesta di informazioni e di contatto,&nbsp;per le attivit&agrave; di
    profilazione e per le comunicazioni commerciali.
  </p>
  <p>
    Tale consenso vale fino a revoca scritta da far pervenire all&rsquo;indirizzo mail
    <a href="mailto:impacto@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <p>In particolare:</p>
  <p>
    L&rsquo;interessato in qualit&agrave; di utente qualificato della piattaforma Speceship raggiungibile
    all&rsquo;indirizzo web
    <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>&nbsp;dichiara di aver
    ricevuto l&rsquo;informativa avendola letta e compresa.
  </p>
</div>
