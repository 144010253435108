import { InjectionToken } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, INIT, MetaReducer, UPDATE } from '@ngrx/store';

import { layoutFeatureKey, reducer as layoutReducer, LayoutState } from './layout/reducer';
import { managementFeatureKey, reducers as mgmtReducers, ManagementState } from './management/reducers/index';
export interface State {
  [layoutFeatureKey]: LayoutState;
  [managementFeatureKey]: ManagementState;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    [layoutFeatureKey]: layoutReducer,
    [managementFeatureKey]: mgmtReducers,
  }),
});

/**
 * Meta reducer used to persist and rehydrate the store.
 */
export const rehydrateAndPersistMetaReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = localStorage.getItem('state');
      if (storageValue) {
        try {
          const storage = JSON.parse(storageValue);
          return setKeysToInitialValue(storage);
        } catch {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('state', JSON.stringify(nextState));
    return nextState;
  };

export const metaReducers: MetaReducer<any>[] = [rehydrateAndPersistMetaReducer];

// Error messages and loading state are persisted to localstorage.
// When rehydrating the state after page refresh the errors and loading state will be present
// causing unexpected behaviors ==> restore fields to their initial state
const setKeysToInitialValue = (storage: any): any => {
  if (storage?.loading.isLoading) {
    storage.loading.isLoading = false;
  }

  if (storage?.auth) {
    if (storage.auth?.auth?.message) {
      storage.auth.auth.message = null;
    }
    if (storage.auth?.tenant?.error) {
      storage.auth.tenant.error = null;
    }
  }

  return storage;
};
