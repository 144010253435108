<div class="container">
  <div>
    <p></p>
  </div>
  <p></p>
  <h1>PRIVACY POLICY SPACESHIP</h1>
  <p>
    INFORMATION ON THE PROCESSING OF PERSONAL DATA PURSUANT TO ARTICLES 12, 13, 14 OF THE GENERAL DATA PROTECTION REGULATION (GDPR)
  </p>
  <i class="text-muted my-2">
    The contents of this document are to be considered intellectual property, protected by applicable national, regional, and international copyright laws. All rights, including those for use, translation, reproduction, and adaptation, in whole or in part, by any means, are reserved globally. These conditions are implicitly accepted by accessing this document and apply to both the digital version and any reproductions.
  </i>
  <p></p>
  <p>April 2024 version</p>
  <p></p>
  <p>
    Regulation (EU) 2016/679, known as the General Data Protection Regulation (GDPR), establishes the protection of individuals concerning the processing of their personal data.
  </p>
  <p>
    Nativa is committed to ensuring that the processing of data relating to a physical person (hereinafter ‘Data Subject’) is based on the principles of accuracy, lawfulness and transparency, as well as the protection of the confidentiality and rights of the Data Subject.
  </p>
  <p>
    Nativa will process your personal data in compliance with applicable regulations, with the utmost care, implementing effective management procedures and safeguards to protect the personal data you have consented to share, taking appropriate measures to prevent unauthorised access or disclosure, maintain data accuracy, and ensure its proper use.
  </p>
  <p>
    Pursuant to Articles 13 and 14 of EU Regulation 679/2016 (GDPR) (hereinafter referred to as the ‘Regulations’) and pursuant to Article 13 of Legislative Decree no. 196 of 30 June 2003 ‘Personal Data Protection Code’, as amended and supplemented by Legislative Decree. 10 August 2018 no. 101, and in line with the principle of transparency, we provide you with the following information to ensure that you are fully informed before materially accessing the Service, Spaceship (hereinafter referred to as the "Service"). The Service can be accessed at the following URLs: <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a>for the website and <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a> for the web application. This information outlines the nature of the data subject to processing and the methods of processing should you choose to provide it.
  </p>
  <h3>1 -  IDENTITY AND CONTACT DETAILS</h3>
  <p>The data processor is: Nativa S.R.L. Società Benefit</p>
  <p>Registered office: Piazzale Clodio, 22 - 00195 - Rome</p>
  <p>email address: <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>&nbsp;</p>
  <p>certified mailbox (PEC): <a href="mailto:nativasrl@legalmail.it">nativasrl&#64;legalmail.it</a>&nbsp;&nbsp;
  </p>
  <h3>2 - WHICH DATA WE PROCESS</h3>
  <p>
    The information that concerns you as the Data Subject and that is processed in the event that you provide it is:
  </p>
  <ul>
    <li>
      Browsing data: during normal use of the platform, the computer systems collect data that, by their nature, do not directly identify users. However, in limited cases, this data could be used to identify individuals when processed or associated with data held by third parties. This category includes, for example, IP addresses, URI (Uniform Resource Identifier) addresses of the resources requested, such as the time of the requests, the characteristics of the device used, the size of the files exchanged or other types of information. This is anonymous or aggregate data that does not normally allow you to be identified and is instead used to identify anomalies and problems. The site also uses technical and analytical cookies, implemented in accordance with current regulations. For further details, please refer to the Cookie Policy;
    </li>
    <li>
      The personal data as simply provided during the compilation of the contact form on the Nativa site or of the information request in the contact email e.g.: name, surname, the company of your affiliation and/or of reference, the location of the company and the contact email address;
    </li>
    <li>
      The personal data collected to enable your use of the Service e.g.: name, surname, email, date of birth, telephone number, and the possibility to add other information at your discretion.
    </li>
  </ul>
  <p>
    NATIVA does not collect and does not receive any so-called special data as defined by art. 9 of the GDPR, that is data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, as well as genetic data, biometric data intended to uniquely identify a physical person, and data relating to the health or sexual life or sexual orientation of the person. Therefore, any data entered in fields reserved for authorised users of the Spaceship platform that falls within this category will be your sole responsibility as the data subject. Such data will only be processed if provided with explicit consent or if it is made publicly available within the data subject's profile. In any case, the Data Processor reserves the right to delete such data.
  </p>
  <p>
    Furthermore, by using our Spaceship service as a User you may disclose personal data of third parties or share documents containing personal data of third parties. As a User, you accept responsibility for the Personal Data of third parties that you publish or share by filling in Spaceship's fields and you guarantee that you have the right to disclose or disseminate such Personal Data, releasing the Data Processor from any liability to third parties. As a User, you also assume responsibility for the truthfulness of the Data and information provided when filling in the Form and registering for the service.
  </p>
  <p>The processing of your personal data is carried out for purposes:</p>
  <ol start="1">
    <li>
      connected and instrumental to the management of your requests for information and contact, as well as the fulfilment of any other consequential obligation;
    </li>
    <li>
      connected and instrumental to the activation and functioning of the services and/or products related to the Spaceship platform provided by Nativa on the basis of your request;
    </li>
    <li>
      of legitimate interest of NATIVA, for example: for the analysis and improvement of its own services; for the performance of statistical analysis and/or market research and development activities, also by analysing the data that derives from service use; to defend its own rights in the course of judicial, administrative or extra-judicial proceedings, and in the context of disputes arising in relation to the services offered;
    </li>
    <li>
      in the case of extraordinary operations of the company to fulfil the legal obligations to which NATIVA is bound by virtue of the activity exercised.
    </li>
  </ol>
  <h4>2.1. - HOW WE COLLECT DATA</h4>
  <p>The Data subject to processing activities by Nativa may be acquired:</p>
  <p></p>
  <p>
    from the Data Subject, including through remote communication methods used by Nativa. For example, data may be collected when the Data Subject completes the contact form on the website  <a href="https://nativalab.com">https://nativalab.com/</a>, or the form present on the website <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a> and/or through the mailbox <a href="mailto:info@nativalab.com">info&#64;nativalab.com</a> or <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a> or through general navigation of the site itself. For further details on this type of data collection, please refer to the relevant Privacy policy.
  </p>
  <p>
    For the purposes of guiding and supporting companies on their sustainability journey, including toward B Corp certification and the adoption of Benefit Company status, and offering information on Benefit Companies, B Corps and sustainability in general, the Spaceship platform will also use data from public sources, such as public registers and lists, documents that can be accessed by anyone (e.g. data contained in the articles of association of companies and published on freely accessible websites, balance sheets, information contained in the company register of the Chambers of Commerce), as well as Data extracted from publicly and generally accessible sources, such as printed or digital newspapers, and information taken from the websites of public bodies or other supervisory and control authorities.
  </p>
  <p>
    Furthermore, for the objectives of the Spaceship platform, data related to the Company's specific purposes of common benefit may be used. Although this data pertains directly to the Company, it may include personal data that is directly or indirectly related to you. Such data may be published on the societàbenefit.net website.
  </p>
  <p></p>
  <p>
    Data related to the Company's specific purposes of common benefit refers to information stored in Spaceship's database within the Benefit Company flow, which will be used by the Company to amend its statute.
  </p>
  <h4>2.2. -  HOW WE PROCESS THE DATA</h4>
  <p>
    NATIVA informs you that it does not market your personal data or use it for any undisclosed purpose.
  </p>
  <p>
    Nativa processes the Personal Data of Data Subjects by adopting appropriate security measures aimed at preventing unauthorised access, disclosure, modification or destruction of the Personal Data.
  </p>
  <p>
    Personal Data may be processed on paper and/or with the aid of computerised tools, whether connected to a network or not, in accordance with the stated purposes and, in any case, in such a way as to guarantee the security and confidentiality of the data, with logics and organisational methods strictly related to the stated purposes. In addition to the Data Processor, certain individuals involved in the management of the impact report - such as administrative, sales, marketing, legal personnel, and system administrators - may have access to the data. External parties, including third-party technical service providers, hosting providers, IT companies, and communication agencies, may also access the data, and may be appointed as additional Data Processors, if necessary.
  </p>
  <p>
    Should the Data Controller intend to further process personal data for a purpose other than the one for which they were collected, prior to such potential further processing, it shall provide the data subject with information regarding such other purpose and any further relevant information.
  </p>
  <p>The recipients of the data are specifically indicated in Section 4.</p>

  <h3>3 - TYPE OF DATA PROCESSED, PURPOSE OF PROCESSING, LEGAL BASIS AND RETENTION PERIOD</h3>
  <p>NATIVA processes data according to the principles and rules of the GDPR.</p>
  <p>
    You may freely provide your Personal Data, except for data that is automatically and anonymously collected during your use of NATIVA's Websites and the Spaceship platform, as specified.
  </p>
  <p>
    PLEASE NOTE: By using or consulting the Websites owned by NATIVA, you explicitly agree, as a data subject, to the relevant privacy policy and you consent to the processing of your personal data in the manner and for the purposes described.
  </p>
  <p>
    The communication of the data is an essential requirement for the fulfilment of the pre-contractual and/or contractual obligations. Failure to provide personal data will result in the inability to provide the services requested, and navigation of the site itself may also be compromised.
  </p>
  <p>
    However, it is always possible to ask the Data Processor to clarify the concrete legal basis of each processing operation.
  </p>
  <p>
    The use of Cookies - or of other tracking tools - by the Spaceship Platform or by the owners of third-party services used, has the purpose of providing the Service requested by the User, in addition to the further purposes described in this document and in the Cookie Policy.
  </p>
  <p>
    In accordance with Article 6 of the GDPR, below we indicate the purpose and the legal basis for the processing of your Data:
  </p>
  <a></a><a></a>
  <div class="table-responsive">
    <table class="table table-dark">
      <tr>
        <td colspan="1" rowspan="1">
          <p>PURPOSE</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>LEGAL BASIS</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>EXPLANATION</p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Requests for preliminary activities, such as requesting information and contacts.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Fulfilment of pre-contractual measures to which you are party pursuant to Art. 6 (1) lit. b) GDPR</p>
          <p></p>
          <p>
            Consent of the data subject (optional, free and revocable at any time) - Art. 6(1)(a) GDPR.
          </p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            When you initiate contact to request services related to registration, inquiries, or the sending of informational material, or to fulfill any other obligations arising from your contact via the email provided on the Nativa site, by completing the dedicated form, or by emailing <a href="mailto:info@nativalab.com">info&#64;nativalab.com</a> or <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>, your data will be processed accordingly.
          </p>
          <p></p>
          <p>
            The provision of data is optional, however, any refusal by the data subject to provide such data will make it impossible for the Data Processor to carry out the service requested.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Registration, authentication, use of the Spaceship platform as a qualified user.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Fulfilment of the contract to which you are party pursuant to Art. 6 no. 1(b) GDPR.</p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            In execution of the Spaceship service agreement you have with Nativa, which allows you to use it as a qualified user.
          </p>
          <p>
            The provision of data is optional, however, the refusal of the interested party to provide the data will make it impossible for the Data Processor to carry out the requested service.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Service Maintenance and Improvement</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legitimate interest</p>
          <p></p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            We minimise the possibility of access to personal data while operating on our platform, in accordance with the terms and conditions of third-party service providers who host the infrastructure where the Spaceship platform is physically located. Please refer to the relevant privacy policy for further details. We implement all necessary technical and legal precautions to protect your rights.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>
            Planning strategic and management activities, market research, and business intelligence and statistical analysis.
          </p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legitimate interest</p>
          <p></p>
          <p>
            Consent of the data subject (optional, free and revocable at any time) - Art. 6(1)(a) GDPR..
          </p>
          <p></p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            To support its corporate objectives and improve the activities and services it offers to customers, Nativa may engage in planning strategic and management activities using business intelligence analysis, market research, and the generation of aggregate and anonymised statistics; these activities will be performed on the basis of the corporate data provided and also through the analysis of data coming from the use of its services,
          </p>
          <p>
            (specifically, data relating to the company's specific common benefit purposes could be used) that, while directly referring to the company, could process personal data with all the necessary legal and technical precautions that is directly and/or indirectly referable to You; such data could be published on the societàbenefit.net website; in any case, personal data processed for statistical purposes will not be used to take decisions or measures regarding individual data subjects, nor for the pursuit of purposes of any other nature.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>General Marketing of the Proprietor</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legitimate interest of the Proprietor</p>
          <p></p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            For example, we may send you promotional and commercial communications via automated contact methods (e-mail) regarding services or products similar to those you have previously used, offered by the Proprietor. This may include, but is not limited to, renewal offers for the Spaceship Service, webinars, or whitepapers, as well as for the purposes of carrying out market research and statistical analyses.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Commercial communications</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Consent of the data subject (optional, free and revocable at any time) - Art. 6(1)(a) GDPR.
          </p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Subject to Your consent, we will process your data in order to inform you, also by means of automated contact tools (e-mail, SMS, push notifications and/or other online positioning, also on social networks, telephone) about our commercial, cultural, training and event organisation initiatives, to carry out market research and to send advertising material. The processing of data for this purpose can only take place on the basis of your free, specific, informed and unequivocal consent; the provision of your data for this purpose is not necessary for the provision of the services you have requested, however, failure to provide your consent - or its revocation, if initially provided - will not allow you to receive the communications listed in this paragraph. We would like to remind you that, in the event that your consent is not given or is withdrawn, you may still receive commercial and promotional communications relating to products and/or services similar to those you have already subscribed to, for which the processing is based on a legitimate interest of the Proprietor as indicated in the previous paragraph.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Profiling</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Consent of the data subject (optional, free and revocable at any time) - Art. 6 (1) lett. a) GDPR.
          </p>
          <p></p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            Subject to Your consent, we will process your personal data as a user of Spaceship, (including through tracking technologies such as cookies, where you have given your consent), to analyse and process information about You in order to define a profile of Your preferences, habits, consumption choices and/or browsing experiences. These profiling activities may also be conducted using tools provided by third parties.
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>Fulfil obligations under applicable national and international regulations and legislation.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legal obligation to which the Company is subject - Art. 6(1)(c) GDPR.</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>
            We may have to provide your data to judicial or other public authorities due to legal obligations or express requests, (e.g. storage of your data for administrative accounting purposes).
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="1" rowspan="1">
          <p>
            Establishing, exercising or defending a right in court or in any case that the judicial authorities exercise their jurisdictional functions.
          </p>
        </td>
        <td colspan="1" rowspan="1">
          <p>Legal obligation Art. 9(2)(f) GDPR</p>
        </td>
        <td colspan="1" rowspan="1">
          <p>We may have an interest in ascertaining and/or exercising a right.</p>
        </td>
      </tr>
    </table>
  </div>
  <p>
    <br />
    After the retention period has expired, the data will be destroyed, deleted or anonymised, subject to technical deletion and backup procedures and the accountability requirements of the Data Processor.
  </p>
  <h3>4 - RECIPIENTS AND POSSIBLE CATEGORIES OF RECIPIENTS OF THE PERSONAL DATA</h3>
  <p>
    The Data may be shared with Nativa's staff, including trainees and consultants, in connection with the performance of assigned tasks. All individuals authorised to process the data act under the direct supervision of the Proprietor, who has provided instructions for its handling.
  </p>
  <p>
    The data may be processed by third-party providers engaged by the Proprietor to deliver services related to the requested service, (such as, for example: assistance, maintenance, product delivery/shipping, additional services, providers of electronic communication networks and services, companies managing digital platforms for data processing, updating, and storage, as well as third-party technical service providers, hosting providers, etc.); external professionals/consultants and consultancy companies; credit and digital payment institutions, banking/postal institutions; financial administration, public bodies, judicial authorities, regulatory and supervisory authorities.
  </p>
  <p>
    The subjects belonging to these categories operate independently as separate Data Processors or as subjects qualified as Data Processors appointed by the Proprietor.
  </p>
  <p>
    In any case, personal data will not be disclosed or made accessible to unspecified parties.
  </p>
  <h3>5 - DATA RETENTION PERIOD</h3>
  <p>
    Personal data are processed for as long as necessary to fulfill the purposes for which they were collected, as outlined in the table in Article 3. After this period, the data are retained for an additional standard period of 10 years from the date of collection, unless the Data Subject exercises their right to withdraw consent.
  </p>
  <p>
    In any case, at regular intervals, we will review whether the personal data you have provided should continue to be processed, and if necessary, we will request your consent again for continued processing.
  </p>
  <p>
    N.B. With regard to personal data processed for the general marketing purposes outlined in Article 3, we clarify that:
  </p>
  <ul>
    <li>
      personal and contact data are kept until you exercise your right to object (‘opt out’) by contacting the Proprietor in the manner described below and,
    </li>
  </ul>
  <p>
    N.B. With regard to personal data processed for commercial purposes (direct marketing) outlined in Article 3, we clarify the following:
  </p>
  <ul>
    <li>
      irrespective of whether an active service exists, your personal data will be processed for commercial communications until you revoke your consent. However, please note that 24 months after your last interaction with our services, your data will no longer be processed for these purposes.
    </li>
  </ul>
  <p>
    This is without prejudice to the Data Subject's right to withdraw consent whenever the processing is based on the User's consent.
  </p>
  <p>
    Indeed, when the processing is based on the User's consent, the Proprietor may keep the Personal Data for a longer period until such consent is revoked. Moreover, the Proprietor may be obliged to keep the Personal Data for a longer period, in compliance with a legal obligation or by order of an authority.
  </p>
  <p>
    After the aforementioned retention periods have expired, the Data will be destroyed, erased or anonymised, subject to technical erasure and backup procedures and the Proprietor's accountability requirements.
  </p>
  <h3>6 - RIGHTS OF THE DATA SUBJECT - SUBMITTING COMPLAINTS TO THE SUPERVISORY AUTHORITY</h3>
  <p>Please note that, with reference to Your personal data, You can exercise the following rights:</p>
  <p>
    You can ask the Proprietor for access to the data concerning Yourself, the data's deletion, the rectification of inaccurate data, the supplementation of incomplete data, and the restriction of processing in the cases provided for by Article 18 GDPR. You can exercise these rights by sending an email to <a href="mailto:impacto@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <p>
    If the processing is based on consent or a contract and is carried out by automated means, you have the right to request data portability. You are entitled to receive your data in a structured, commonly used, and machine-readable format, and, where technically feasible, to transmit it to another Proprietor without hindrance.
  </p>
  <p>
    You have the right to withdraw your consent at any time for marketing and/or profiling purposes, as well as to object to processing based on the legitimate interests of the Proprietor, for reasons related to your particular situation.
  </p>
  <p>
    You have the right to lodge a complaint with the competent supervisory authority in the Member State where you habitually reside or work, or of the State where the alleged infringement occurred. (For more details, see the website of the Privacy Guarantor <a href="http://www.garanteprivacy.it">www.garanteprivacy.it</a>)
  </p>
  <h4>6.1 - OPPOSITION TO GENERIC MARKETING</h4>
  <p>
    You may at any time decide to stop receiving communications related to generic marketing activities by contacting the Proprietor at any time, writing to <a href="mailto:spaceship@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <h3>7 - METHODS AND PLACE OF PROCESSING</h3>
  <p>
    The processing of your personal data may involve any operation or set of operations described in Article 4 of the GDPR. The personal data you provide will be stored in our paper and/or electronic records, with procedures strictly aligned with the specified purposes, adopting appropriate technical and organisational measures to protect the data. Personal data will be processed using appropriate instruments and procedures to guarantee security and confidentiality and may be processed, directly and/or through delegated third parties with whom the Proprietor has entered into appropriate agreements, using computerised or electronic means. The personal data you provide will be processed at the Data Controller's head office and in any other place where the parties involved in the processing are located.
  </p>
  <p>
    This is without prejudice to the Proprietor's right to transfer such data to a third country or international organisation where the European Commission has issued an adequacy decision, or where appropriate safeguards are in place, and the means to obtain a copy of the data or details of where it has been made available (in the case of transfers under Article 46, 47, or the second paragraph of Article 49 of the GDPR). In such cases, you will be given timely notice of the transfer.
  </p>
  <h3>8 - CHANGES TO THIS PRIVACY POLICY</h3>
  <p>
    The Proprietor reserves the right to make changes to this privacy policy at any time by informing Users on this page and, if possible, on this wesbite, as well as, if technically and legally feasible, by sending a notification to Users through one of the contact details held by the Proprietor. We therefore recommend that you regularly review this page, noting the date of the last modification at the bottom. If any changes impact processing based on consent, the Proprietor will, if necessary, obtain the User's consent again.
  </p>
  <h3>9 - CONSENT TO DATA PROCESSING</h3>
  <p>
    Pursuant to Art. 6, paragraph 1, letter a) of the GDPR, by signing up for the Service and engaging in subsequent actions as a registered User of the platform, the User provides consent for the processing of their data in accordance with the purposes and methods described above, where consent is legally required. Specifically, the User consents to the sharing of their data with third parties as outlined in this notice, as well as for activities such as preliminary requests (e.g., information and contact inquiries), profiling, and commercial communications.
  </p>
  <p>
    This consent remains valid until revoked in writing, which can be sent to the email address <a href="mailto:impacto@nativalab.com">spaceship&#64;nativalab.com</a>.
  </p>
  <p>In particular:</p>
  <p>
    The interested party, as a registered user of the Spaceship platform accessible at <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>, confirms having received, read, and understood the provided information.
  </p>
</div>
