<div class="container mt-4">
  <h1>GENERAL TERMS AND CONDITIONS</h1>
  <i class="text-muted my-2">
    The contents of this document are to be considered intellectual property, protected by applicable national, regional, and international copyright laws. All rights, including those for use, translation, reproduction, and adaptation, in whole or in part, by any means, are reserved globally. These conditions are implicitly accepted by accessing this document and apply to both the digital version and any reproductions.
  </i>
  <p class="mt-2">June 2024 version</p>
  <p>
    These general terms and conditions (hereinafter also referred to as the ‘General Terms & Conditions’) govern the terms and conditions of the provision to the Company (hereinafter also referred to as the ‘Service Recipient’) registered to the Service known as ‘Spaceship’ (hereinafter also referred to as the ‘Service’). Said service can be reached at the following addresses: <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a> for the website and  <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a> for the web app.
  </p>
  <p>
    The Service Provider reserves the right to periodically review these General Terms and Conditions.
  </p>
  <p>
    The General Terms and Conditions proposed to the Service Recipient are hereby made available for storage and reproduction (in accordance with the provisions of the decree implementing European Directive 2000/31/EC).
  </p>
  <h3 id="h.vvtg40ccvr95">DEFINITIONS</h3>
  <p>
    Supplier and/or Service Provider (hereinafter also ‘Nativa’) means ‘Nativa Srl SB’, whose registered office is located at Piazzale Clodio, 22 - 00195 - Rome (P.IVA IT07929980964). Email address is <a href="mailto:info@nativalab.com">info&#64;nativalab.com</a>.
  </p>
  <p>
    'Spaceship Software' refers to the Software that is the exclusive property of the Supplier, who holds all intellectual property rights without exception, and which is used to create the Service.
  </p>
  <p>
    The term 'Service' refers to the Service offered by the Supplier to the Company registered at <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>. Better defined below, this Service enables companies to initiate their sustainability journey using some of the most nationally and internationally recognised tools, with the subsequent construction of a plan to improve their sustainability profile.
  </p>
  <h3 id="h.7b0txyl62qwl">0. FOREWORD</h3>
  <p>
    NATIVA is the Regenerative Design Company that supports hundreds of companies in a radical evolution of their business, accelerating the transition from extractive to regenerative economic models. It is the first Benefit Company in Europe and B Corp&#8482; in Italy.
  </p>
  <p>The Company that intends to use the Service:</p>
  <ul>
    <li>
      is aware that the current context imposes the need to equip oneself with solid tools and reference models for evaluating the impact that company choices have on people and the environment;
    </li>
    <li>wants to embark on a sustainability pathway;</li>
    <li>
      said path is designed in alignment with the Company's Vision, Mission, and Values, and aims to build upon past sustainability efforts, enhance the creation of value for all stakeholders, and drive the work to a more advanced and impactful level.
    </li>
  </ul>
  <h3 id="h.d8jb5w5w73f9">CONTRACTUAL DOCUMENTS AND EFFECTIVENESS</h3>
  <p>
    The complete terms governing the provision of the Service to the Company are outlined in these General Terms and Conditions, as well as in the Order and/or Application Form signed online by the Company. This document contains the specific terms and conditions of the contract (hereinafter referred to as the 'Order' and/or 'Application Form'), such as the selected Plan, the chosen Service model, and the applicable price (excluding VAT).
  </p>
  <p>
    The General Terms and Conditions and the Order are hereinafter jointly referred to as the ‘Contract’.
  </p>
  <p>
    The execution of the Contract shall take place upon the Service Provider's correct and punctual receipt of the Order, compiled and accepted by the Company in all its parts. Submission of their Order implies full acceptance by the Company of all the terms and conditions described in these General Terms and Conditions, subject to any amendments and/or additions that may be made by the Service Provider, and of all other documents that constitute the Contract.
  </p>
  <p>
    Following the completion of payment as described in Article 6, the Service Provider shall send a link to the email address indicated in the Order through which the Company can create its credentials to access the Service. It is understood, in any case, that the use of the Service by the Customer certifies acceptance of all the contractual conditions. By submitting the Order, the Company acknowledges and accepts that it enters into a contract whose only valid and effective version is that in the Italian language, and that any version provided by the Supplier in any other language are made available solely as a courtesy.
  </p>
  <p>
    Any differing or additional terms or conditions proposed by the Company, or referenced in its documents, shall have no effect on the Supplier unless expressly accepted in writing by the same. 
  </p>
  <h3 id="h.ug1n9tppdsxg">1. SCOPE OF THE GENERAL TERMS AND CONDITIONS</h3>
  <p>
    1.1. The General Conditions apply to the provision of the Service for the duration, payment and any other conditions indicated in the Order signed by the Company..
  </p>
  <h3 id="h.qe85812yxvqj">2. THE SERVICE</h3>
  <p>
    2.1. By entering into the Contract, the Supplier agrees to provide the Service specified in the Order, either directly or through third parties, to the Company which accepts the Service upon punctual payment of the Fees established in the Order.
  </p>
  <p>
    2.2. The Company may use the Service exclusively at the web address <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a>, using its own access credentials (for which it is responsible) as specified in Article 4. The Company is granted a non-exclusive license to use the Software, in accordance with the terms of the license, for the entire duration of the selected plan.
  </p>
  <p>
    2.3. The Supplier undertakes to provide the Service specified in the Order exclusively to the Company, therefore, it is understood that third parties, including Subsidiaries of the same group as the Company, may NOT use the Service specified in the Order.
  </p>
  <p>The services covered by this contract include the following activities:</p>
  <p>
    The Service is designed in an "integrated self-service" model, allowing the Client Company to operate independently through a dedicated platform that provides the necessary tools for managing the sustainability process. In the case that the MARS service plan is selected, the Company also gains access to assistance in using the Service from the Spaceship Support Team; it is specified that such support consists of assistance on the use and/or operation of the <i>software</i> and does not have the nature of consultancy activities. It will be provided by the Spaceship Support Team following the acceptance of the request by the Company on weekdays, during working hours 9:00 - 18:00, excluding holidays and company closures.
  </p>
  <p>The Service allows the company to</p>
  <ul>
    <li>access the Programmes on the platform;</li>
    <li>
      access an online document archive that provides a shared space for the Company to store all the important documents necessary for the completion of the programme;
    </li>
    <li>
      access to the latest news and events related to the subject matter of the programmes available on the platform;
    </li>
    <li>
      access to a knowledge base on the topics of the programmes available on the platform;
    </li>
    <li>
      access support from the Spaceship Support Team, as defined above, in the case of selection of the MARS plan.
    </li>
  </ul>
  <p>
    The Service Recipient expressly acknowledges that the limitation of liability outlined in Article 9, particularly with regard to the Service, is an essential condition for the services provided by the Supplier at the costs specified in this contract. Therefore, beyond the explicitly agreed terms, the Service Recipient waives any right to pursue remedial actions without reservation.
  </p>
  <p>
    2.4. All activities that are not expressly the subject of the Contract shall NOT be covered by the scope of the Contract, including but not limited to:
  </p>
  <p>
    2.4.1. all certification activities, inasmuch as Spaceship is not a certification service;
  </p>
  <p>
    2.4.2. all strategic activities preceding and following the formulation of a proposed action plan for improvement;
  </p>
  <p>
    2.4.3. all third-party activities resulting from and/or related to the Company's sustainability journey, including those offered by third parties through the website accessed via the provided address, which the Software redirects to. This includes, for example, the measurement of the Company's economic, social, and environmental performance, as well as any compensation actions the Company chooses to implement from the proposed improvement plan;

  </p>
  <p>
    2.4.4. all specific backup activities of the data and/or content (in the general sense) input and/or processed by the Company. Therefore, the Company acknowledges and accepts that it shall be its responsibility alone to obtain and maintain a copy of the data and/or content (in a general sense) input and/or processed through the Service, it being understood that once the Contract is terminated and/or in the event of cancellation and/or termination of a singular plan and/or the Contract in general, such data and/or content (in a general sense) may no longer be recoverable. In any event, the Company releases the Service Provider from any and all liability for any loss or total or partial damage to data and/or content (in a general sense) input and/or processed by the Company through the Service. The Company is solely responsible for restoring any data and/or content (in a general sense) input and/or processed by it, upon reactivation of the Service, which may require the conclusion of a new Contract if necessary;

  </p>
  <p>
    2.4.5. all consultancy and support activities that are NOT related and/or connected to the operation and use of the <i>software</i>.
  </p>
  <p>
    2.5. The Supplier's obligation to provide the Service is based on the information received from the Company and the Company assumes full and exclusive responsibility for such information. The Company acknowledges that by not providing the requested information, it shall not be able to use the Service.
  </p>
  <p>
    2.6. The Company acknowledges and agrees that the Provider, to enhance and continuously improve the Service, has the right to use the data entered by the Company in an anonymous and/or aggregated form for planning strategic activities, conducting market research, business intelligence, and statistical analysis. It is specified that by ‘anonymised form’ we mean data managed in such a way that it cannot be traced back to the Company that entered it and that by ‘aggregated form’ we mean data processed together with others in such a way as to make it extremely difficult to obtain the original data entered by the Company.
  </p>
  <p>
    2.7 The Company also acknowledges that the Supplier may use the provided data for general marketing activities, including promotional and commercial initiatives related to services/products similar to Spaceship, including but not limited to proposals for renewal, webinars, whitepapers, and direct marketing campaigns, i.e., activities related to the Supplier's commercial, cultural, training, and event organisation initiatives.

  </p>
  <p>
    2.8. The Parties undertake to provide full cooperation to ensure the Company can maximize the use of the Service.
  </p>
  <p>2.9. On the basis of shared principles and values, the Parties undertake to:</p>
  <p>
    &#9679; share the internal decision-making process in order to jointly identify reference timelines, milestones and decisive steps to avoid any deadlocks in dialogue that may arise - Transparency
  </p>
  <p>
    &#9679; give feedback on the input provided by the other party in a timely manner so as to mutually facilitate the performance of activities and allow objectives to be achieved within the agreed timeframe - Communication
  </p>
  <p>
    &#9679; each other’s roles to ensure that the activities described are always carried out with the purpose benefiting all parties involved, both in the short term and over the medium to long term – Commitment
  </p>
  <h3 id="h.u5vhi7n0i8v0">3.  OBLIGATIONS OF THE CUSTOMER</h3>
  <p>3.1. The Company, by the Contract, undertakes to:</p>
  <p>(a) pay the Fees due and stipulated in the Order;</p>
  <p>
    (b) supply all the Information and provide all the guarantees necessary for the Supplier to perform its obligations under the Contract; for such information and guarantees the Company assumes full and exclusive responsibility;;
  </p>
  <p>
    (c) equip itself independently with the resources, hardware and software, and adequate Connectivity to be able to use the Service;
  </p>
  <p>
    (d) use the Software and the Service in a manner consistent with the Agreement and exclusively for the purposes for which it is intended.
  </p>
  <h3>4. ACCESS CREDENTIALS</h3>
  <p>
    4.1. The Service Recipient may use the Service by authenticating itself at the web address: <a href="https://spaceship-app.nativatools.com">https://spaceship-app.nativatools.com</a> using its credentials, defined in the link sent to the email address indicated in the Order; using this link, the user may independently set a password that is saved in the encrypted database.
  </p>
  <p>
    The Service Recipient acknowledges that the credentials it manages shall comply with the standards of reference for credential management.
  </p>
  <p>
    4.2 The Supplier shall therefore not have the Access Credentials and shall not be able to use the Service directly in place of the Company.
  </p>
  <p>
    4.3 The Company accepts that although the Supplier (including through Third Parties) may not directly access the profile dedicated to the Company (so-called Account) and use its Service, it shall have the right to use, in an anonymous and/or aggregate form, the data entered by the Company to plan strategic and management activities, conduct market research, business intelligence, and statistical analysis, as better specified in Article 2.
  </p>
  <p>
    4.4. The Company is aware of the fact that knowledge of the access credentials by third parties would allow the latter unauthorised use of the Service and access to any data entered. The Company shall in any case be held solely responsible for any use, authorised or unauthorised, of the Service by means of the Access Credentials.
  </p>
  <p>
    4.6. the Company is obliged to keep and ensure that each of its Users keeps, manages and periodically updates the Access Credentials with the utmost diligence and confidentiality, undertaking not to hand them over or allow them to be used by any third party not expressly authorised to do so.
  </p>
  <p>
    4.7. The Supplier and/or its Partners, if any, may in no case be held liable for any direct and/or indirect damage that may be caused to the Company, to each User and/or to third parties as a result of the failure to comply with the provisions of this article.
  </p>
  <p>5. EVOLUTION OF THE SERVICE</p>
  <p>
    5.1. The Company acknowledges and agrees that, at the Supplier’s exclusive discretion, the evolution of the Service may result in the modification or removal of certain functionalities.
  </p>
  <p>
    5.2. The Company shall exonerate the Supplier from any liability related to any damages resulting from potential evolutions of the Service, unless such damages result from the wilful misconduct or gross negligence of the Supplier.
  </p>
  <h3 id="h.yeektofd1svj">6.  FEES, PAYMENT TERMS AND CONDITIONS, RESELLER OF THE SERVICE</h3>
  <p>
    6.1. The Company undertakes to pay the FEE for the provision of the Service indicated in the Order, to which you are directed from the web page of the Service <a href="https://spaceship.nativatools.com">https://spaceship.nativatools.com</a>, in accordance with the terms and timeframes provided by and dependent on the plan chosen: said FEE could contain one or more programmes and is also dependent on the selected Service plan, the plan named “Moon” or the plan named “Mars”.
  </p>
  <p>Specifically:</p>
  <ul>
    <li>the "one-time" activation fee for the Service;</li>
    <li>the annual subscription fee for each year of the Service.</li>
  </ul>
  <p>
    he Company acknowledges and agrees that it is solely responsible for timely payment of the activation fee and for ensuring the automatic renewal of the Service to maintain continuity. This must be done before the Service is deactivated due to the expiration of the Contract, taking into consideration the processing time of payments.
  </p>
  <p>
    Effective from the operative date of these General Terms and Conditions, payment of the Fee is managed through the Payment Service provided by Paddle.com Market Ltd (hereinafter referred to as "Paddle"), in accordance with its Terms and Conditions for corporate customers, available at the following web address: <a href="https://www.paddle.com/legal/invoiced-business-terms">https://www.paddle.com/legal/invoiced-business-terms</a> and which the Company is invited to read carefully.
  </p>
  <p>
    Unless a specific, separate and different agreement is reached between the Parties, the payment of the fee due for the subscription to the Service shall be made by the Company at the same time as the placing of the Order and, in any case, prior to the activation of the same Service, in accordance with the modalities chosen by the Company during the telematic purchase procedure. These modalities are prominently described on the Order summary page, managed by Paddle, to which the Company is redirected following the completion of the form present on the Service purchase page.
  </p>
  <p>
    6.2. All Fees shall be exclusive of V.A.T. and any other statutory charges. Paddle will issue an invoice for each payment made by the Company. All amounts invoiced will be subject to the VAT due and any other tax charges arising from the execution of the Contract, which will be borne by the Company. In any event, the Company releases the Service Provider and Paddle as reseller from any and all liability arising from transactions or payments made.
  </p>
  <p>
    6.3. The Company acknowledges and expressly accepts that the Fees are subject to annual updating to the extent of 100% of the increase in the ISTAT index of service production prices, calculated as an average of the previous twelve months.
  </p>
  <p>
    6.4. The Customer acknowledges that the Service is subject, by its very nature, to constant technological and regulatory evolution that requires continuous and onerous updating, development and, in some cases, replacement activities necessary to guarantee its functionality. Consequently, the Supplier shall have the right to modify the Fees even beyond the ISTAT index.
  </p>
  <p>
    6.5. Notwithstanding the provisions of the paragraph above, if, during the performance of the Contract, circumstances should arise that are dependent on the Cloud providers on which the infrastructure is based or in any case unforeseeable circumstances such as to make the provision of the Service by the Supplier more onerous, the latter shall have the right to receive a fair one-off payment or to unilaterally amend the Fee.
  </p>
  <p>
    6.6. In the event of non-payment or delayed payment of any amount due under the Contract, or if such payment is reasonably deemed overdue by Paddle, as the Service reseller, the Supplier reserves the right to suspend and/or terminate access to the Service and any related services. This is without prejudice to the exercise of the express termination clause outlined below. Paddle in its capacity as reseller of the Service may, according to its own terms and conditions of service indicated at <a href="https://www.paddle.com/legal/invoiced-business-terms"
    >https://www.paddle.com/legal/invoiced-business-terms</a>, by way of example but not limited to: (i) terminate the Contract, with the defaulting Party being responsible for the payment of the amount due as provided in the Order; and/or (ii) preclude access to the Service.
    During the suspension and/or interruption of the Service, the Customer shall not have access to data and/or information and/or content entered and/or processed by the Software in the provision of the Service.
  </p>
  <p>
    If within a reasonable period of time after the suspension and/or interruption of the Service the Company has not made payment, the Service shall be deactivated.
  </p>
  <p>
    6.7. The Company waives the right to raise objections without having previously fulfilled its payment obligations under this Article.
  </p>
  <h3 id="h.jia3mznyyjuv">7. PARTNERS</h3>
  <p>
    The Supplier, in performing its obligations in accordance with the Contract, may, at its exclusive discretion, avail itself of the technical, organisational and commercial cooperation of its Partners and/or consultants, to whom it may entrust the performance of some or all of the activities listed in the Contract.
  </p>
  <h3 id="h.4j4pvjafez9z">8. INTELLECTUAL PROPERTY</h3>
  <p>
    8.1. All Intellectual Property rights, including associated economic exploitation rights, except for third-party rights, in the Software, Service, documentation, videos, graphic interfaces, site content, developments, preparatory works, derivative works, and trademarks or logos related to or contained within the Software and Service, are and will remain the exclusive property of the Provider, in whole and in part, worldwide.
  </p>
  <p>
    8.2. Following the conclusion of the Agreement and for the limited duration of the Agreement, the Supplier grants the Company a non-exclusive and non-transferable Licence to use the Software exclusively for the use of the Service, in accordance with the terms and conditions of the Agreement.
  </p>
  <p>
    8.3. The Company undertakes, also expressly for each of its Users, to use the Software and the Service itself, within the limits of the Licence granted under Article 8.2 and in compliance with the Provider's Intellectual Property rights. In any case, without prejudice to the mandatory legal limits, the Company may not, by way of example and without limitation: (a) circumvent the technical limitations and technological protection measures present in the Software, including the authentication system; (b) market the Software and Service and any distinctive feature associated with them for any reason whatsoever.
  </p>
  <p>
    8.4. All rights to trademarks, logos, names, domain names and other distinctive features in any way associated with the Software and the Service also remain with the Supplier and/or, where applicable, the third-party owner, with the consequence that the Company may not use them in any way without the prior authorisation of the Supplier (and/or the third-party owner).
  </p>
  <p>
    8.5. In particular, all rights of trademarks, logos, names, domain names, other distinctive features and  general content in any way associated with B Lab&#8482; are expressly the intellectual property of B Lab&#8482;; furthermore, intellectual property of B Lab&#8482;, including the names ‘Certified B Corporation&#8482;’, ‘B Corporation&#8482;’, ‘B Corp&#8482;’, and the ‘Certified B Corporation&#8482;’ logo, are registered trademarks. Any direct and/or indirect reference in the Software and/or the Service to trademarks, logos, names, domain names, other distinctive features and general content that is the intellectual property of B lab&#8482; is made solely for the purpose of disclosure and education.
  </p>
  <h3 id="h.ec6gqsp3amc0">9. RESPONSIBILITIES AND DECLARATIONS OF THE CUSTOMER</h3>
  <p>
    9.1. By accepting the General Terms and Conditions, the Company declares that: (i) it has all the rights and powers necessary to conclude and fully and effectively execute the Contract, and that (ii) it intends to use the Software and the Service as part of its entrepreneurial, artisanal, commercial or professional activity and that, therefore, the provisions of Legislative Decree 206/2005 for the protection of consumers (and subsequent amendments and additions), do not apply to it.
  </p>
  <p>
    9.2. The Company undertakes to ensure that the provisions of the Contract are complied with by each of its Users, including their respective employees and/or collaborators. Also pursuant to Article 1381 of the Italian Civil Code, the Company shall be held solely responsible for the actions of such parties and also guarantees compliance with all applicable regulations, including tax and civil law.
  </p>
  <p>
    9.3. In general, the Service Provider is not responsible for the information stored by the Service Recipient. Specifically, it is forbidden to use the Service in an improper manner, in order to deposit, store, send, publish, transmit and/or share data, applications or IT documents that, by way of example but not limited to (a) conflict with or violate Intellectual Property rights owned by the Provider and/or third parties; (b) have discriminatory, defamatory, slanderous or threatening content; (c) contain pornographic, child pornographic, obscene material or in any case material contrary to public morals; (d) contain viruses or, in any case, other computer elements of contamination or destruction; (e) constitute spamming, phishing and/or similar activities; (f) are in any case in conflict with applicable laws and/or regulations.
  </p>
  <p>
    9.4. The Supplier reserves the right to suspend the provision of the Service, or to prevent access to the Service, if it becomes aware of a violation of the provisions of this Article and/or an express request to that effect is made by a judicial or administrative body in accordance with the laws in force. In this case, the Supplier shall inform the Company of the reasons for the suspension, without prejudice to the right to terminate the Contract pursuant to Article 17.
  </p>
  <p>
    9.5. The Company acknowledges that the Service may use Open Source software and agrees, on behalf of itself and its Users, to comply with the applicable terms and conditions
  </p>
  <h3 id="h.xvgvdsm43ya8">10. WITHDRAWAL FROM THE MARKET AND REPLACEMENT</h3>
  <p>
    The Company acknowledges that the Service and the environments in which it operates are subject, by their nature, to constant evolution that may determine their obsolescence and, in some cases, the advisability of withdrawal from the market and/or, possibly, replacement with new technological solutions. Therefore, the Supplier and/or any Third-Party cloud providers on which the cloud infrastructure of the Service is based, have the right, at their exclusive discretion, even during the term of this Contract, to withdraw from the Service and/or related cloud components from the market (possibly replacing them with new technological solutions). In this case: (a) the Supplier shall inform the Customer, (also) by means of the publication on the website, with a suitable notice, that it intends to withdraw the Service from the market (the ‘Obsolete Service’); (b) the above notice (‘Withdrawal Notice’) shall include a description of the possible new Service (the ‘New Service’) that shall replace the Obsolete Service, it being understood that the New Service may be based on different technologies; (c) in the event of replacement with a New Service, the Customer shall have the right, exercisable within 15 days from the date of the Withdrawal Notice, to withdraw from the Contract; otherwise, the Contract shall continue to be effective with respect to the New Service and any reference to the Obsolete Service shall be deemed to refer to the New Service.
  </p>
  <h3 id="h.2wdy0x1rxghq">11. INDEMNITY</h3>
  <p>
    The Company agrees to indemnify and hold the Supplier harmless from any damage, claim, liability, or expense, whether direct or indirect, including reasonable legal fees, arising from the Company’s or any User's failure to fulfil any obligations under the Contract, particularly those outlined in Article 3. (Obligations of the Customer), 4. (Access Credentials), 6. (Fee, Payment Terms and Conditions, Reseller of the Service), 8. (Intellectual Property), 9. (Responsibilities and Declarations of the Customer), 10. (Withdrawal from the Market and Replacement), 15. (Duration, Termination of Service and Refund Policy), and 21. (Transfer of the Contract).
  </p>
  <h3 id="h.k5lzak1vemyu">12. SUPPLIER'S RESPONSIBILITY</h3>
  <p>
    12.1. The Supplier accepts only the obligations specified in the Contract and guarantees that the Service will be provided professionally, in accordance with the standard diligence of the relevant industry.
  </p>
  <p>
    12.2. The Supplier shall not be obliged to intervene in the event of malfunctions due to the Company's failure to comply with the operating rules, misuse and/or atypical use of the Service.
  </p>
  <p>
    12.3. This warranty is subject to the proper functioning and proper use of the Company's hardware infrastructure, system software and network resources, including those owned by third parties, and the proper use of the Service.
  </p>
  <p>
    12.4. The Company accepts and acknowledges that no software product is error-free and acknowledges that it has been specifically advised of the need to back-up data.
  </p>
  <p>
    12.5. The Provider shall not be held liable for any damages, direct or indirect, of any nature and extent, that may be caused to the Company and/or each User and/or third parties as a result of the use of the Service in a manner that does not comply with the provisions of the Contract and/or applicable laws.
  </p>
  <p>
    12.6. The Provider shall not be liable in any way for any malfunction and/or failure to use the Service that results from inadequate Connectivity.
  </p>
  <p>
    12.7. Under no circumstances shall the Supplier be held liable for any damages or losses, of any nature or entity, resulting from initiatives implemented through the Service, as all preliminary tasks (such as correctly identifying the data to be entered), and all consequent activities, as well as any activities related to obtaining B Corp&#8482; certification, adopting the status of Benefit Company, and implementing the improvement action plan, are the exclusive responsibility of the Company.
  </p>
  <p>
    12.8. Unless this is necessary to comply with legal provisions and/or requests of judicial authorities, the Provider, is not obliged in any way to verify the data and content entered by the Client and/or each User through the Service and, therefore, cannot in any way be held liable for damages and/or losses, direct or indirect and of any nature, arising from errors and/or omissions of such data or related to their nature and/or characteristics.
  </p>
  <p>
    12.9. Without precluding the Supplier's responsibility for the conformity of the Service with the characteristics described for the permitted uses and purposes for which it was made, the Supplier, subject to the mandatory limits of the law, shall in no case be held liable for any damage (direct or indirect), cost loss and/or expense that the Company and/or third parties may suffer as a result of computer attacks, hacking activities and, in general, abusive and unauthorized access by third parties to the Service, the Software and, in general, to the Company's and/or Supplier's computer systems, from which the following may result, by way of example but not limited to: (i) failure to use the Service; (ii) loss and/or theft of data owned by or otherwise available to the Company; and (iii) damage to the hardware and/or software systems and/or to the Customer's Connectivity.
  </p>
  <p>
    12.10. Except in the case of wilful misconduct or gross negligence, the Supplier's liability shall never exceed the amount of the Fees paid by the Company under this Contract in the year in which the event giving rise to the Supplier’s liability occurred. The Supplier shall not be liable for any loss of profit, loss of earnings, indirect damages, data loss, downtime, loss of business opportunities, penalties, delays, or any other liabilities incurred by the Company and/or its Subsidiaries to third parties.
  </p>
  <p>
    12.11 The Supplier does not assume or provide any warranty as to the quality, capability, operations, performance and suitability of the third-party software and third-party machinery with which the Software and consequently the Service operates. All matters relating to the quality, capacity, operations, performance, suitability and general operation of third-party software, services and third-party machinery are the exclusive responsibility and liability of the owner of the economic exploitation rights and/or the Licensee, reseller or supplier of such software, services or machinery.
  </p>
  <h3 id="h.504ydyyjo7y7">13. UNILATERAL AMENDMENTS</h3>
  <p>
    13.1. The Company acknowledges and expressly agrees to the possibility that the Supplier may need to adapt or modify the Service, or its future evolutions, due to the intrinsic characteristics of the Service, particularly in response to technological advancements, regulatory changes, and market demands. The Supplier reserves the right to make such changes at any time, giving prior notice to the Company.
  </p>
  <p>
    13.2. In such cases, the Company has the right to withdraw from the Contract by providing written notice to the Supplier via registered mail with return receipt or by certified mail, within 15 days of receiving the notice, including notices published on the page regarding the General Terms and Conditions of the Contract by the Supplier, as referred to in the preceding paragraph.
  </p>
  <p>
    13.3. If the Customer fails to exercise its right of withdrawal, within the terms and in the manner indicated above, the amendments to the Contract shall be deemed to be definitively known and accepted by the latter and shall become definitively effective and binding.
  </p>
  <h3 id="h.dhyrsvtyjmpy">14. SUSPENSION AND INTERRUPTION</h3>
  <p>
    14.1. The Supplier shall take all reasonable actions to ensure maximum availability of the Service within the limits of the obligations set forth in the Contract, with the exception of what is directly and/or indirectly attributable to the cloud providers hosting the Company's cloud infrastructure. The Company, however, acknowledges and accepts that the Supplier may suspend and/or interrupt the supply of the Service, subject to prior notice, should ordinary or extraordinary maintenance work be necessary to the Cloud Infrastructure and/or the Software. In such cases, the Supplier undertakes to restore the availability of the Service as soon as possible.
  </p>
  <p>
    14.2. Subject to the provisions of Articles 6.6, 9, and 17.2, and without prejudice to the authority of the relevant cloud provider, the Supplier also reserves the right to suspend or interrupt the provision of the Service: (a) in the event of non-payment or delayed payment, in whole or in part, of the Fees; (b) for reasons of security and/or confidentiality; (c) in the event of violation, by the Customer and/or each User and/or each Subsidiary, of the legal obligations regarding the use of computer services and the Internet; (d) in the event of issues with the Cloud Infrastructure and/or Software that require suspension of access to resolve, including cases of replacement and/or migration (even partial). Each case is subject to the Supplier providing prior written notice to the Customer, explaining the reasons for the suspension and the expected duration of the intervention.
  </p>
  <h3 id="h.5r5ge8szdni">15. DURATION, TERMINATION OF THE SERVICE AND REFUND POLICY</h3>
  <p>
    The Contract shall remain in effect between the Parties for a period of twelve (12) months from the date of execution. Upon expiration, it shall automatically renew for successive twelve (12) month periods, unless cancelled by the Company. Cancellation may be communicated at any time through the dedicated cancellation and/or withdrawal procedure within the application or via the designated Paddle procedure.
  </p>
  <p>
    In any event, the Supplier agrees to send a notice regarding the Company's right to cancel to the email address provided during registration for the Service: this notice shall be sent at the end of each year of Service provision, starting from the date of execution of the Contract.

  </p>
  <p>
    In the event of cancellation, the Company will retain access to the Service until the end of the relevant billing period. 
    <u>
      Payments are non-refundable, whether in full or for partially used subscription periods, or for any unused Service, except in the event that the Supplier exercises its right of withdrawal for reasons other than those specified in the express termination and ban clause outlined in Article 17; only in this case shall the Company be entitled to a refund of the fee for the period of unused Service, provided it has already been paid.
    </u>
  </p>
  <p>
    If you cancel the Service, the relevant account will be closed automatically at the end of the current billing period. If you registered for the Service using a third-party account as your Payment Method, you may need to cancel your subscription through that third party. This can be done by accessing your account settings with the third party and turning off the auto-renewal option, or by cancelling the Service directly through the third party.
  </p>
  <h3 id="h.a75js9yty8wg">16. WITHDRAWAL</h3>
  <p>
    16.1. The Parties acknowledge their respective right to withdraw from the Contract as specified below.
  </p>
  <p>
    The Company has the right to withdraw from the Contract at any time by providing notice through the dedicated cancellation and/or withdrawal procedure within the application or via the designated Paddle procedure. The Company shall hold the Supplier harmless for any expenses incurred and work performed up to the date of withdrawal.
  </p>
  <p>Specifically, from the effective date of the notice:</p>
  <p>&#9679; the Supplier shall cease all contractual services;</p>
  <p>
    &#9679; the Supplier shall be entitled to payment for what has been performed according to the terms and conditions, and fees set out in the contract.
  </p>
  <p>
    The Supplier has the right to withdraw from the Contract at any time either through the dedicated procedure for termination of the Service within the application or through Paddle's dedicated procedure.
  </p>
  <p>
    16.2. In the event that the Supplier exercises its right to withdraw for reasons other than those referred to in the following paragraph, the Company shall be entitled to a refund of the fee for the period of non-use of the Service, if it has already been paid.
  </p>
  <h3 id="h.2ihff4gkk372">17. EXPRESS TERMINATION AND SERVICE BAN CLAUSE</h3>
  <p>
    17.1. Without prejudice to compensation for damages, the Supplier reserves the right to terminate the Contract in accordance with Article 1456 of the Civil Code by sending a simple written notice to the email address provided by the Company, or through formal communication such as a certified electronic mail (PEC) or registered letter with return receipt, in the event of the Company's and/or any User's failure to comply with any of the following provisions: 3 (Obligations of the Customer), 4 (Access Credentials), 6 (Fee, Payment Terms and Conditions, Reseller of the Service), 8 (Intellectual Property), 9 (Responsibilities and Declarations of the Customer), 10 (Withdrawal from the Market and Replacement), 11 (Indemnity), 15 (Duration, Termination of Service and Refund Policy), and 21 (Assignment of Contract).
  </p>
  <p>
    17.2. Without prejudice to the Company's obligation to pay the Fees referred to in Article 6, the Supplier also reserves the right to discontinue the provision of the Service at any time in the event of: (i) failure by the Customer and/or each User to fulfil one of the obligations referred to in paragraph 17.1; failure by the Company to fulfil any obligations under any further contracts entered into with the Supplier, where such non-fulfilment constitutes grounds for termination of those contracts, if applicable. In such cases, the Supplier shall give notice of its intention to discontinue the provision of the Service, inviting the Company, where possible, to remedy the breach within a specified period.
  </p>
  <p>18. EFFECTS OF TERMINATION OF CONTRACT AND RETURN OF VERSION</p>
  <p>
    18.1. If the Contract is terminated for any reason whatsoever, the Supplier shall immediately and permanently cease to provide the Service.
  </p>
  <p>
    18.2. Unless otherwise agreed between the Parties, the Supplier shall permanently delete the Company's data within 30 (thirty) days of the Contract's termination, in compliance with legal obligations.
  </p>
  <p>
    18.3. It is in any case understood that the following provisions shall survive the termination, whatever the reason, of the Contract: 1 (Scope of the General Conditions), 3 (Obligations of the Customer), 6. (Fee, Terms and Conditions of Payment, Reseller of the Service), 8 (Intellectual Property), 9 (Responsibilities and Declarations of the Customer), 10 (Withdrawal from the Market and Replacement), 11 (Indemnity), 12 (Supplier's Responsibility), 15 (Duration, Termination of Service and Refund Policy), 19 (Communications), 20 (Applicable Law, Exclusive Jurisdiction and Final Provisions), 21 (Transfer of the Contract).
  </p>
  <h3 id="h.uytxj05j8xd4">19. COMMUNICATIONS</h3>
  <p>
    All communications related to the Contract may be sent to the email address provided by the Company. The Customer is responsible for promptly notifying the Supplier of any changes to this email address for communication purposes.
  </p>
  <h3 id="h.7asp5nh401av">20. APPLICABLE LAW, EXCLUSIVE JURISDICTION AND FINAL PROVISIONS</h3>
  <p>
    20.1 This Agreement shall be governed by and construed in accordance with the laws of Italy.
  </p>
  <p>
    20.2 This Agreement supersedes any prior agreements between the Parties contained in pre-existing oral or written agreements relating to its fulfilment.
  </p>
  <p>
    20.3 If one or more clauses of this Contract are rendered null and void or are rendered unenforceable by the effect of the law or by a decision imposed on the Parties, this shall not have the effect of causing the invalidity of the whole of this Contract, nor shall it affect the validity and binding nature of the other clauses as a whole.
  </p>
  <p>
    20.4 The Supplier reserves the right to unilaterally amend and/or supplement these General Terms and Conditions, without prejudice to the Company's right of Withdrawal. By continuing to use the Service after the amendments take effect, the Company agrees to be bound by the revised terms.
  </p>
  <p>
    20.5 Any failure to enforce one or more of your rights under the Contract shall not, however, be construed as a conclusive waiver of those rights and shall not prevent you from enforcing them at any other time.
  </p>
  <p>
    20.6 Any dispute as to the interpretation and/or execution of this Agreement shall be submitted to the jurisdiction of the Court of Milan.
  </p>
  <h3>21. TRANSFER OF THE CONTRACT</h3>
  <p>
    Except with the prior written consent of the Supplier, the Company is prohibited from transferring the Contract, in whole or in part.
  </p>
  <h3>22. PROCESSING OF PERSONAL DATA</h3>
  <p>
    22.1. The Parties acknowledge and agree that the execution of this Contract and the provision of the Service will involve the collection and processing of personal data of the Service Recipient (and of related parties, such as proxies, legal representatives, etc.) by the Supplier, for the purposes necessary for the performance of the Contract and in compliance with Personal Data Protection Legislation and any other applicable legal provisions. As the data processor, the Supplier agrees to process such data in accordance with the privacy notice provided by the Supplier pursuant to Article 13 of the GDPR and available on the Service's website <a href="https://spaceship-app.nativatools.com/privacy-policy">https://spaceship-app.nativatools.com/privacy-policy</a>.
  </p>
  <p>
    22.2. It is understood that the Company shall remain fully responsible for ensuring compliance with all obligations provided for by the GDPR and the Personal Data Protection Legislation concerning any Third-Party Personal Data it inputs, in its role as data controller. The Supplier assumes no liability for any consequences arising from the Company's failure to meet its obligations as the data controller.
  </p>
  <hr />
</div>
